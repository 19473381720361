import { atom, DefaultValue } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const modeAtom = atom({
    key: "modeAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('modeAtom'),
    ]
});

export const langAtom = atom({
    key: "langAtom",
    default: 'en',
    effects_UNSTABLE: [
        localStorageEffect('langAtom'),
    ]
});

export const authAtom = atom({
    key: "authAtom",
    default: 'default',
    effects_UNSTABLE: [
        localStorageEffect('authAtom'),
    ]
});

export const userAtom = atom({
    key: "userAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('userAtom'),
    ]
});

export const tokenAtom = atom({
    key: "tokenAtom",
    default: '',
    effects_UNSTABLE: [
        localStorageEffect('tokenAtom'),
    ]
});

// export const paramAtom = atom({
//     key: "paramAtom",
//     default: ''
// });

// export const pathAtom = atom({
//     key: "pathAtom",
//     default: { main: 'home', sub: '', isToggle: false }
// });