import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import Moment from 'moment';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { Transition, Menu } from '@headlessui/react';
import * as XLSX from 'xlsx';
import { useAlert } from 'react-alert';

import animPage from '../../assets/anim/anim-pageload.json';
import ServiceLearn from '../../services/serviceLearn';

export default function PanelCourseInfo(props) {

    const { id } = useParams();

    const [token] = useRecoilState(tokenAtom);

    const [lesson, setLesson] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [enrollment, setEnrollment] = useState([]);

    const [course, setCourse] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [query, setQuery] = useState('');
    const [queryB, setQueryB] = useState('');
    const [tabNav, setTabNav] = useState('report');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [pageSizeB, setPageSizeB] = useState(10);
    const [pageCurrentB, setPageCurrentB] = useState(0);
    const [pageTotalB, setPageTotalB] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isDate, setIsDate] = useState(false);
    const [isDateB, setIsDateB] = useState(false);

    const alert = useAlert();

    const navigate = useNavigate();

    let learnService = new ServiceLearn();

    const goBack = () => {
        navigate(`/home/course`);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const handleOnChangeB = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSizeB':
                setPageSizeB(value);
                break;
            case 'searchB':
                setQueryB(value);
                break;
        }
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setIsDateB(true);
        setSDate(start);
        setEDate(end);
    }

    const updateStatus = (id, status) => {
        let body = {
            id: id,
            status: status,
        }
        learnService.updateEnrollment(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const changeTab = (tab) => {
        setTabNav(tab);
        setQuery('');
        setQueryB('');
    }

    const exportToExcel = () => {
        if (tabNav === 'report') {
            const worksheet = XLSX.utils.json_to_sheet(enrollment);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "enrollment");
            XLSX.writeFile(workbook, "enrollment.xlsx");
        } else {
            const worksheet = XLSX.utils.json_to_sheet(reviews);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "reviews");
            XLSX.writeFile(workbook, "reviews.xlsx");
        }
    }

    const exportToCsv = () => {
        if (tabNav === 'report') {
            let headers = ['fname,lname,email,phone,enrollment,status'];
            let usersCsv = enrollment.reduce((acc, row) => {
                const { fname, lname, email, phone, createdAt, status } = row
                acc.push([fname, lname, email, phone, createdAt, status].join(','))
                return acc
            }, []);
            downloadFile({
                data: [...headers, ...usersCsv].join('\n'),
                fileName: 'enrollment.csv',
                fileType: 'text/csv',
            });
        } else {
            let headers = ['username,banner,comment,rate,status,createdAt'];
            let usersCsv = reviews.reduce((rows, item) => {
                const { username, banner, comment, rate, status, createdAt } = item
                rows.push([username, banner, comment, rate, status, createdAt].join(','))
                return rows
            }, []);
            downloadFile({
                data: [...headers, ...usersCsv].join('\n'),
                fileName: 'reviews.csv',
                fileType: 'text/csv',
            });
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": { _id: id },
                "page": 1,
                "size": 1,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var doc = await learnService.getCourse(body, token);
            setCourse(doc.data.data[0]);
            setLesson(doc.data.data[0].lesson);

            let bodyA = {
                "filter": { eid: id, etype: 'course' },
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            }
            var docA = await learnService.getEnrollment(bodyA, token);
            setEnrollment(docA.data.data);
            setPageCurrent(docA.data.currentPage);
            setPageTotal(docA.data.totalPage);

            let bodyB = {
                "filter": { cid: id },
                "page": 1,
                "size": parseInt(pageSizeB),
                "searchQuery": queryB,
                "startDate": isDateB ? sdate.toISOString() : "",
                "endDate": isDateB ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            }
            var docB = await learnService.getCReviews(bodyB, token);
            setReviews(docB.data.data);
            setPageCurrentB(docB.data.currentPage);
            setPageTotalB(docB.data.totalPage);

            setLoading(false);
        })();
    }

    const pageChange = (pageValue) => {
        setLoading(true);
        (async function () {
            let bodyA = {
                "filter": { eid: id, etype: 'course' },
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            }
            var docA = await learnService.getEnrollment(bodyA, token);
            setEnrollment(docA.data.data);
            setPageCurrent(docA.data.currentPage);
            setPageTotal(docA.data.totalPage);
            setLoading(false);
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    const pageChangeB = (pageValue) => {
        setLoading(true);
        (async function () {
            let bodyB = {
                "filter": { cid: id },
                "page": pageValue,
                "size": parseInt(pageSizeB),
                "searchQuery": queryB,
                "startDate": isDateB ? sdate.toISOString() : "",
                "endDate": isDateB ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            }
            var docB = await learnService.getCReviews(bodyB, token);
            setReviews(docB.data.data);
            setPageCurrentB(docB.data.currentPage);
            setPageTotalB(docB.data.totalPage);
            setLoading(false);
        })();
    }

    const nextPageB = () => {
        if (pageCurrentB < pageTotalB) {
            pageChangeB(pageCurrentB + 1);
        }
    }

    const prevPageB = () => {
        if (pageCurrentB > 1) {
            pageChangeB(pageCurrentB - 1);
        }
    }

    useEffect(() => {
        props.onTap();
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, pageSizeB, queryB, sdate, edate]);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all courses</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Course Report</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    All information related to course.
                                </p>
                                <hr />
                            </div>
                        </div>
                        <div className='col-span-12'>
                        </div>
                        <div className="bg-white col-span-12 mt-2">
                            {
                                course !== {} && <div className="cursor-pointer px-6 py-4 whitespace-nowrap">
                                    <div className='flex'>
                                        <div>
                                            <img src={course.banneurl === "" ? 'https://via.placeholder.com/260x160' : course.banneurl} alt='Banner image' className='rounded w-[260px] h-[160px]' />
                                        </div>
                                        <div className='ml-6 flex flex-col'>
                                            <div className="text-2xl font-medium text-gray-900 uppercase">
                                                {course.title} | <span>Lessons ({lesson.length})</span>
                                            </div>
                                            <div className="text-sm font-serif text-gray-400 uppercase">
                                                Created: {Moment(course.createdAt).format('MMMM DD, YYYY - hh:mm a')}
                                            </div>
                                            <div className='flex mt-2'>
                                                <span className="px-4 py-1 mr-4 inline-flex leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-600 uppercase">
                                                    <div className='flex items-center justify-center'>
                                                        <span className='mr-2 text-sm'>{course.review}</span>
                                                        <i className="las la-star text-sm"></i>
                                                    </div>
                                                </span>
                                                {
                                                    course.rate !== 0 && <span className="px-4 py-1 inline-flex w-[60px] text-sm leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase text-center">
                                                        ${course.rate}
                                                    </span>
                                                }
                                                {
                                                    course.rate === 0 && <span className="px-4 py-1 inline-flex w-[60px] text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase">
                                                        FREE
                                                    </span>
                                                }
                                            </div>
                                            <div className='flex-grow'></div>
                                            {
                                                course.status === "published" && <div className="uppercase mb-2 flex items-center">
                                                    <div className='mr-2 w-5 h-5 bg-green-600 text-white flex items-center justify-center p-1 rounded-full'><i className="las la-check text-xs"></i></div>
                                                    <p className='text-sm text-green-800 font-bold font-serif'>Published</p>
                                                </div>
                                            }
                                            {
                                                course.status === "draft" && <div className="uppercase mb-2 flex items-center">
                                                    <div className='mr-2 w-5 h-5 bg-red-600 text-white flex items-center justify-center p-1 rounded-full'>
                                                        <i className="las la-inbox text-xs"></i>
                                                    </div>
                                                    <p className='text-sm text-red-600 font-bold font-serif'>Draft</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                course === {} && <div className='flex items-center justify-center w-full h-full'>
                                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                </div>
                            }
                        </div>
                        <div className='col-span-12'>
                            <div class="w-full mt-2">
                                <div className='w-full bg-white rounded-md p-4 mt-4'>
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tabNav === 'report' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('report') }}>Report</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tabNav === 'review' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('review') }}>Reviews</button></li>
                                        </ul>
                                    </div>
                                    {
                                        tabNav === 'report' && <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                            <div className="col-span-12 cursor-pointer">
                                                <div className="py-4">
                                                    <h3 className='text-lg font-sans font-medium'>ENROLLMENTS</h3>
                                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                                        <thead className="bg-gray-50">
                                                            <tr className=''>
                                                                <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                                                    <div className='w-full grid grid-cols-12 gap-2'>
                                                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                                            <div className='col-span-4'>
                                                                                <RangeDatePicker
                                                                                    startDate={sdate}
                                                                                    endDate={edate}
                                                                                    minDate={new Date(2022, 8, 1)}
                                                                                    maxDate={Moment().toDate()}
                                                                                    disabled={false}
                                                                                    onCloseCalendar={(e) => {
                                                                                        loadData();
                                                                                    }}
                                                                                    onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                                    className=""
                                                                                    startWeekDay="monday"
                                                                                />
                                                                            </div>
                                                                            <div className='w-full flex col-span-8 items-center'>
                                                                                <div className='w-[80px]'>
                                                                                    <p>Search :</p>
                                                                                </div>
                                                                                <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email, and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                                            </div>
                                                                        </form>
                                                                        <div className='col-span-1 flex items-center'>
                                                                            <Menu>
                                                                                <Menu.Button>
                                                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                                                </Menu.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0">
                                                                                    <Menu.Items>
                                                                                        <div
                                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                                            <div>
                                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToExcel();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToCsv();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Name
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Email ID
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Phone number
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Enrolled At
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Status
                                                                </th>
                                                                <th scope="col" className="px-2 py-3"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-gray-50 divide-y divide-gray-200">
                                                            {
                                                                enrollment.length !== 0 && enrollment.map((enroll) => {
                                                                    return <tr className="hover:bg-gray-100">
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {enroll.fname} {enroll.lname}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {enroll.email}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                            <div>
                                                                                <div className="text-sm text-gray-500">
                                                                                    Ph.: +243 {enroll.phone.substring(1)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                            <div>
                                                                                <div className="text-sm text-gray-500">
                                                                                    {Moment(enroll.createdAt).format('MMM, DD yyyy')}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${enroll.status == 'paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                                {enroll.status.toUpperCase()}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Menu>
                                                                                <Menu.Button>
                                                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                                                </Menu.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0">
                                                                                    <Menu.Items>
                                                                                        <div
                                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                                            <div>
                                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                                    {
                                                                                                        enroll.status == 'unpaid' && <Menu.Item>
                                                                                                            <li className='w-32 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                                                updateStatus(enroll._id, 'paid');
                                                                                                            }}>
                                                                                                                <div className='flex items-center text-base'>
                                                                                                                    <i className="las la-certificate text-lg"></i>
                                                                                                                    <p className='text-sm font-serif ml-2'>Paid</p>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    <Menu.Item>
                                                                                                        <li className='w-32 cursor-not-allowed hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                                            updateStatus(enroll._id, 'cancel');
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <i className="las la-trash text-lg"></i>
                                                                                                                <p className='text-sm font-serif ml-2'>Cancel</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                enrollment.length == 0 && <tr>
                                                                    <td colSpan={5} className='w-full'>
                                                                        <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2 w-full'>
                                                                            <p className='text-center'>No enrollments found.</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className='flex py-2 px-4 items-center bg-blue-50'>
                                                        <p className='text-sm font-serif'>Show rows per page</p>
                                                        <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={40}>40</option>
                                                            <option value={50}>50</option>
                                                        </select>
                                                        <div className='flex-grow'></div>
                                                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        tabNav === 'review' && <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                            <div className="col-span-12 cursor-pointer">
                                                <div className="py-4">
                                                    <h3 className='text-lg font-sans font-medium'>REVIEWS</h3>
                                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                                        <thead className="bg-gray-50">
                                                            <tr className=''>
                                                                <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                                                    <div className='w-full grid grid-cols-12 gap-2'>
                                                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                                            <div className='col-span-4'>
                                                                                <RangeDatePicker
                                                                                    startDate={sdate}
                                                                                    endDate={edate}
                                                                                    minDate={new Date(2022, 8, 1)}
                                                                                    maxDate={Moment().toDate()}
                                                                                    disabled={false}
                                                                                    onCloseCalendar={(e) => {
                                                                                        loadData();
                                                                                    }}
                                                                                    onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                                    className=""
                                                                                    startWeekDay="monday"
                                                                                />
                                                                            </div>
                                                                            <div className='w-full flex col-span-8 items-center'>
                                                                                <div className='w-[80px]'>
                                                                                    <p>Search :</p>
                                                                                </div>
                                                                                <input type="text" value={queryB} onChange={handleOnChangeB} name='searchB' placeholder="Search by username and comment." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                                            </div>
                                                                        </form>
                                                                        <div className='col-span-1 flex items-center'>
                                                                            <Menu>
                                                                                <Menu.Button>
                                                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                                                </Menu.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0">
                                                                                    <Menu.Items>
                                                                                        <div
                                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                                            <div>
                                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToExcel();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToCsv();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    User
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Review
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Rating
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Date
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-gray-50 divide-y divide-gray-200">
                                                            {
                                                                reviews.length !== 0 && reviews.map((review) => {
                                                                    return <tr className="hover:bg-gray-100">
                                                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                                <div className='flex items-center text-blue-600'>
                                                                                    <img src={review.banner.length === 0 ? 'https://via.placeholder.com/150' : review.banner} alt="User Image" className='w-10 h-10 rounded-full mr-2 border border-black' />
                                                                                    {review.username}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {review.comment}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-yellow-200 text-yellow-600">
                                                                                <p><i className="las la-star text-sm"></i> {review.rate.toFixed(2)}</p>
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm text-blue-600">
                                                                                {Moment(review.createdAt).format('MMM DD, YYYY')}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${review.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                                {review.status}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                reviews.length == 0 && <tr>
                                                                    <td colSpan={5} className="w-full">
                                                                        <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2 w-full'>
                                                                            <p className='text-center'>No reviews found.</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className='flex py-2 px-4 items-center bg-blue-50'>
                                                        <p className='text-sm font-serif'>Show rows per page</p>
                                                        <select className='text-sm ml-2 bg-gray-100' name="pageSizeB" onChange={handleOnChangeB} value={pageSizeB}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={40}>40</option>
                                                            <option value={50}>50</option>
                                                        </select>
                                                        <div className='flex-grow'></div>
                                                        <p className='text-sm font-serif mr-2'>Page {pageCurrentB} of {pageTotalB}</p>
                                                        <div onClick={() => { prevPageB() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrentB <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                                        <div onClick={() => { nextPageB() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-200 ml-2 text-black ${pageCurrentB === pageTotalB ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    );
}
