import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceFeedback {
    lookupService = new APIServiceLookUp();

    async getFeedback(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}feedback/getFeedback`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getContacts(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}feedback/getContacts`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}