import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'moment';

import animPage from '../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceAsset from '../../services/serviceService';

export default function PanelNotification(props) {

    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [notifications, setNotifications] = useState([]);
    const [notificationsList, setNotificationsList] = useState([]);

    const [query, setQuery] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    const alert = useAlert();

    let assetsService = new ServiceAsset();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        body: Yup.string().required('This information is required'),
        channel: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            title: '',
            body: '',
            channel: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                title: values.title,
                body: values.body,
                channel: values.channel,
            }
            assetsService.setNotification(body, token).then((res) => {
                if (res.status) {
                    resetForm();
                    setIsOpen(false);
                    loadData();
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
                setSubmit(false);
            });
        }
    });

    const deleteNotification = (id) => {
        let body = {
            id: id
        };
        assetsService.delNotification(body, token).then((res) => {
            if (res.status) {
                loadData();
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
        });
    }

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = notificationsList.filter((u) => {
                return u.title.toLowerCase().includes(value.toLowerCase())
            });
            setNotifications(list);
        } else {
            setNotifications(notificationsList);
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await assetsService.getNotification(body, token);
            setNotifications(doc.data.data);
            setNotificationsList(doc.data.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpen} onClose={() => {
                setIsOpen(false);
                resetForm();
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Send Notification &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            resetForm();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Select Receivers<sup className="text-red-600">*</sup></label>
                            <select type="text" id='channel' value={values.channel} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. CLEANERS" onChange={handleChange}>
                                <option value="">-- Select Notification --</option>
                                <option value="all">To Everyone</option>
                                <option value="customer">To Customers</option>
                                <option value="technician">To Technicians</option>
                                <option value="company">To Compnies</option>
                            </select>
                            {(errors.channel && touched.channel) && <p className='text-xs text-red-400 mt-1'>{errors.channel}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Notification Title<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Notification Body<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="body" value={values.body} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.body && touched.body) && <p className='text-xs text-red-400 mt-1'>{errors.body}</p>}
                        </div>
                        <div className='flex items-end justify-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "SEND"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> {t('pageA25')}
                                </h2>
                                <div className='flex'>
                                    <Menu>
                                        <Menu.Button>
                                            <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-plus mr-2"></i> Add Push</button>
                                        </Menu.Button>
                                        <Transition
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0">
                                            <Menu.Items>
                                                <div
                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                                    <div>
                                                        <ul className='flex flex-col items-start p-2'>
                                                            <Menu.Item>
                                                                <li className='w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md' onClick={() => {
                                                                    setIsOpen(true);
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className='text-sm font-serif ml-2'>Send Notification</p>
                                                                    </div>
                                                                </li>
                                                            </Menu.Item>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <TopBar />
                                </div>
                            </div>
                        </div>
                        <hr className='col-span-12 mt-2' />
                        <div className="col-span-12 mt-4">
                            <div className="intro-y bg-white w-full shadow rounded-md p-2">
                                <form className="flex">
                                    <div className="flex items-center rounded border p-2 h-9 w-1/2 shadow-inner">
                                        <i className="las la-search"></i>
                                        <input type="text" value={query} onChange={handleOnChangeSearch} placeholder="Search by notification." className="w-full ml-2 font-sans text-sm outline-none" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="intro-y mt-6 col-span-12">
                            <div className='bg-white w-full shadow rounded-md p-4 mt-2'>
                                <ul className='w-full'>
                                    {
                                        notifications.length !== 0 && notifications.map((event) => {
                                            return <li className='px-2 py-2 border mt-1 rounded'>
                                                <div className='flex items-center'>
                                                    <div>
                                                        <h3 className="text-lg font-medium text-gray-900">{event.title}</h3>
                                                        <p className='text-xs text-gray-600 mt-1'><span className='bg-green-200 text-green-800 text-xs rounded-full px-4 py-1'>Sucess</span> {Moment(event.createdAt).format('DD/MM/YY, hh:mm:ss z').toString()}</p>
                                                    </div>
                                                    <div className='flex-grow'></div>
                                                    <p className='text-gray-600 text-sm text-center mr-4'>
                                                        <span className='text-xl text-black'>{event.click}</span><br />
                                                        <span>Clicked</span>
                                                    </p>
                                                    <div className='col-span-2 flex justify-end'>
                                                        <Popover className="relative">
                                                            <Popover.Button>
                                                                <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-xl"></motion.i>
                                                            </Popover.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Popover.Panel>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-136px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    deleteNotification(event._id);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-pen text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Popover.Panel>
                                                            </Transition>
                                                        </Popover>
                                                    </div>
                                                </div>
                                                <hr className='mt-2' />
                                                <p className="text-sm text-gray-400 mt-1">{event.body}</p>
                                            </li>
                                        })
                                    }
                                    {
                                        notifications.length === 0 && <li className='px-2'>
                                            <div className='flex items-center justify-center'>
                                                <h3 className="font-medium font-serif text-blue-600">{t('misc5')}</h3>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    )
}