import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, authAtom, langAtom, tokenAtom } from '../core/config/atoms';

import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from "react-i18next";

import TopBar from '../core/widgets/ui/TopBar';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceUser from '../services/serviceUser';

export default function LayoutSetting() {

    const [userData, setUserData] = useRecoilState(userAtom);
    const [token] = useRecoilState(tokenAtom);

    const [, setAuthStatus] = useRecoilState(authAtom);
    const [, setLang] = useRecoilState(langAtom);

    const [isOpenEmail, setIsOpenEmail] = useState(false);
    const [isOpenPhone, setIsOpenPhone] = useState(false);
    const [isOpenPassword, setIsOpenPassword] = useState(false);
    const [isOpenUsername, setIsOpenUsername] = useState(false);
    const [isOpenDisable, setIsOpenDisable] = useState(false);
    const [isEn, setIsEn] = useState(false);

    const [submit, setSubmit] = useState(false);

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const alert = useAlert();
    const userService = new ServiceUser();

    var A = { email: '', password: '' };
    var B = { phone: '', password: '' };
    var C = { opass: '', npass: '', cpass: '' };
    var D = { msg: '' };
    var E = { fname: '', lname: '', password: '' };

    const formVSchemaA = Yup.object().shape({
        email: Yup.string().email(t('stsStr2')).required(t('stsStr1')),
        password: Yup.string().required(t('stsStr1')),
    });

    const formVSchemaB = Yup.object().shape({
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: t('stsStr3') }).required(t('stsStr1')),
        password: Yup.string().required(t('stsStr1')),
    });

    const formVSchemaC = Yup.object().shape({
        opass: Yup.string().required(t('stsStr1')),
        npass: Yup.string().required(t('stsStr1')),
        cpass: Yup.string().required(t('stsStr1')),
    });

    const formVSchemaD = Yup.object().shape({
        msg: Yup.string().required(t('stsStr1')),
    });

    const formVSchemaE = Yup.object().shape({
        fname: Yup.string().required(t('stsStr1')),
        lname: Yup.string().required(t('stsStr1')),
        password: Yup.string().required(t('stsStr1')),
    });

    const getInitValues = () => {
        if (isOpenEmail) {
            return A;
        }
        if (isOpenPhone) {
            return B;
        }
        if (isOpenPassword) {
            return C;
        }
        if (isOpenDisable) {
            return D;
        }
        if (isOpenUsername) {
            return E;
        }
        return A;
    }

    const getInitSchema = () => {
        if (isOpenEmail) {
            return formVSchemaA;
        }
        if (isOpenPhone) {
            return formVSchemaB;
        }
        if (isOpenPassword) {
            return formVSchemaC;
        }
        if (isOpenDisable) {
            return formVSchemaD;
        }
        if (isOpenUsername) {
            return formVSchemaE;
        }
        return formVSchemaA;
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setErrors } = useFormik({
        initialValues: getInitValues(),
        validationSchema: getInitSchema(),
        enableReinitialize: true,
        onSubmit: values => {
            if (isOpenEmail) {
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    nemail: values.email,
                    password: values.password
                };
                userService.updateEmail(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenEmail(false);
                        setUserData(res.data);
                        alert.show(t('stsStr4'), { type: 'success' });
                    } else {
                        alert.show(res.msg, { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenPhone) {
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    phone: values.phone,
                    password: values.password
                };
                userService.updatePhone(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenPhone(false);
                        setUserData(res.data);
                        alert.show(t('stsStr5'), { type: 'success' });
                    } else {
                        alert.show(res.msg, { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenPassword) {
                if (values.npass != values.cpass) {
                    setErrors({ ...errors, cpass: t('stsStr6') });
                    return;
                }
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    opass: values.opass,
                    npass: values.npass
                };
                userService.updatePassword(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenPassword(false);
                        logout();
                        alert.show(t('stsStr7'), { type: 'success' });
                    } else {
                        alert.show(res.msg, { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenUsername) {
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    fname: values.fname,
                    lname: values.lname,
                    password: values.password
                };
                userService.updateUsername(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenUsername(false);
                        setUserData(res.data);
                        alert.show(t('stsStr5'), { type: 'success' });
                    } else {
                        alert.show(res.msg, { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenDisable) {
                if (values.msg !== "DISABLE") {
                    setErrors({ ...errors, msg: t('stsStr8') });
                } else {
                    setSubmit(true);
                    let body = {
                        uid: userData._id
                    };
                    userService.deactiveUser(body, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            setIsOpenDisable(false);
                            logout();
                        } else {
                            alert.show(res.msg, { type: 'error' });
                        }
                        setSubmit(false);
                    });
                }
            }
        }
    });

    const getUserTitle = (type) => {
        switch (type) {
            case "SADMIN":
                return "SUPER ADMIN";
            case "ADMIN":
                return "ADMIN";
            case "MANAGER":
                return "Manager";
            case "EDIT":
                return "Editor";
            case "ACCOUNT":
                return "Accountant";
            default:
                return "";
        }
    }

    const changLang = (lan) => {
        i18n.changeLanguage(lan);
        setLang(lan);
        switch (lan) {
            case 'en':
                setIsEn(true);
                break;
            case 'fr':
                setIsEn(false);
                break;
        }
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    useEffect(() => {
        setIsEn(i18n.language === 'en');
    }, []);

    return (
        <div className='h-screen w-full'>
            <div className="w-full h-full p-2 overflow-hidden">
                <Dialog open={isOpenEmail} onClose={() => {
                    setIsOpenEmail(false);
                    resetForm();
                }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t('setng1')} &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenEmail(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng11')}<sup className="text-red-600">*</sup></label>
                                <input type="email" id="email" value={values.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. jhon.deo@gmail.com" onChange={handleChange} />
                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng12')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                            </div>
                            <div className='flex items-end'>
                                <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && t('setng13')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog open={isOpenPhone} onClose={() => {
                    setIsOpenPhone(false);
                    resetForm();
                }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t('setng1')} &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenPhone(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng14')}<sup className="text-red-600">*</sup></label>
                                <input type="text" id="phone" value={values.phone} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" maxLength={10} minLength={10} placeholder="i.e. 1234567890" onChange={handleChange} />
                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng12')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                            </div>
                            <div className='flex items-end'>
                                <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && t('setng13')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog open={isOpenPassword} onClose={() => {
                    setIsOpenPassword(false);
                    resetForm();
                }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t('setng1')} &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenPassword(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng12')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="opass" value={values.opass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.opass && touched.opass) && <p className='text-xs text-red-400 mt-1'>{errors.opass}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng15')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="npass" value={values.npass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.npass && touched.npass) && <p className='text-xs text-red-400 mt-1'>{errors.npass}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng16')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="cpass" value={values.cpass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.cpass && touched.cpass) && <p className='text-xs text-red-400 mt-1'>{errors.cpass}</p>}
                            </div>
                            <div className='flex items-end'>
                                <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && t('setng11')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog open={isOpenDisable} onClose={() => {
                    setIsOpenDisable(false);
                    resetForm();
                }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t('setng1')} &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenDisable(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm text-gray-900">{t('setng17')} <span className='font-bold'>DISABLE</span><sup className="text-red-600">*</sup></label>
                                <input type="text" id="msg" value={values.msg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 focus:outline-none focus:shadow focus:shadow-blue-500/50 focus:border-blue-400 uppercase" maxLength={7} placeholder="DISABLE" onChange={handleChange} />
                                {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                            </div>
                            <div className='flex items-end'>
                                <button type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-800`} disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && t('setng18')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog open={isOpenUsername} onClose={() => {
                    setIsOpenUsername(false);
                    resetForm();
                }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t('setng1')} &nbsp;
                            </h3>
                            <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                setIsOpenUsername(false);
                                resetForm();
                            }}>
                                <i className="las la-times text-sm text-black"></i>
                            </span>
                        </div>
                        <hr className='mt-2' />
                        <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">First Name<sup className="text-red-600">*</sup></label>
                                <input type="text" id="fname" value={values.fname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Jhon" onChange={handleChange} />
                                {(errors.fname && touched.fname) && <p className='text-xs text-red-400 mt-1'>{errors.fname}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Last Name<sup className="text-red-600">*</sup></label>
                                <input type="text" id="lname" value={values.lname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Deo" onChange={handleChange} />
                                {(errors.lname && touched.lname) && <p className='text-xs text-red-400 mt-1'>{errors.lname}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('setng12')}<sup className="text-red-600">*</sup></label>
                                <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                            </div>
                            <div className='flex items-end'>
                                <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && t('setng13')}
                                </button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <div className="m-2">
                    <div className="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            {t('setng1')}
                        </h2>
                        <div className='flex'>
                            <TopBar />
                        </div>
                    </div>
                    <hr className='mt-2' />
                    <div className="col-span-12 mt-4">
                        <div className="intro-y w-full shadow-lg">
                            <div className='h-28 bg-green-600 rounded-t-lg relative'>
                                <div className='bg-green-600 w-20 h-20 rounded-full flex items-center justify-center border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                    <h3 className='text-white text-xl'>{userData.fname[0].toUpperCase()}{userData.lname[0].toUpperCase()}</h3>
                                </div>
                            </div>
                            <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                <div className='flex items-center justify-between mt-1 mb-4'>
                                    <div className='text-2xl font-bold ml-24'>{userData.fname} {userData.lname} <span className='text-lg text-gray-600'>({getUserTitle(userData.role)})</span></div>
                                </div>
                                <div className='bg-gray-00 mt-2 p-4 rounded'>
                                    <div className='flex items-center justify-between'>
                                        <div>
                                            <div className='text-xs text-gray-600'>{t('setng2')}</div>
                                            <div className='text-lg text-black'>{userData.fname} {userData.lname} <span className='text-sm text-gray-600'>#{userData.uid}</span></div>
                                        </div>
                                        <div className='cursor-pointer bg-green-600 text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                            setIsOpenUsername(true);
                                        }}>{t('setng10')}</div>
                                    </div>
                                    <div className='flex items-center justify-between mt-3'>
                                        <div>
                                            <div className='text-xs text-gray-600'>{t('setng3')}</div>
                                            <div className='text-base text-black'>{userData.email}</div>
                                        </div>
                                        <div className='cursor-pointer bg-green-600 text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                            setIsOpenEmail(true);
                                        }}>{t('setng10')}</div>
                                    </div>
                                    <div className='flex items-center justify-between mt-3'>
                                        <div>
                                            <div className='text-xs text-gray-600'>{t('setng4')}</div>
                                            <div className='text-base text-black'>+243 {userData.phone}</div>
                                        </div>
                                        <div className='cursor-pointer bg-green-600 text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                            setIsOpenPhone(true);
                                        }}>{t('setng10')}</div>
                                    </div>
                                    <div className='mt-3'>
                                        <div className='text-xs text-gray-600'>Language</div>
                                        <div className='w-full my-2 hover:bg-gray-100 px-4 py-2 flex rounded cursor-pointer' onClick={() => {
                                            changLang('en');
                                        }}>
                                            <input type="radio" for="en" className='mr-2 cursor-pointer' checked={isEn} />
                                            <label id='en' className='cursor-pointer'>English</label>
                                        </div>
                                        <div className='w-full my-2 hover:bg-gray-100 px-4 py-2 flex rounded cursor-pointer' onClick={() => {
                                            changLang('fr');
                                        }}>
                                            <input type="radio" for="fr" className='mr-2 cursor-pointer' checked={!isEn} />
                                            <label id='fr' className='cursor-pointer'>French</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='my-4' />
                    <h2 className='text-2xl font-medium truncate'>{t('setng5')}</h2>
                    <div className='cursor-pointer bg-green-600 text-base font-serif mt-4 w-40 rounded px-2 py-2 text-white text-center' onClick={() => {
                        setIsOpenPassword(true);
                    }}>
                        {t('setng6')}
                    </div>
                    <hr className='my-4' />
                    <h2 className='text-2xl font-medium truncate'>{t('setng7')}</h2>
                    <h2 className='text-base truncate'>{t('setng8')}</h2>
                    <div className='flex'>
                        <div className='bg-red-400 cursor-pointer text-base font-serif mt-4 w-40 rounded px-2 py-2 text-white text-center' onClick={() => {
                            setIsOpenDisable(true);
                        }}>
                            {t('setng9')}
                        </div>
                        <div className='ml-4 border-2 cursor-pointer border-red-400 text-red-400 text-base font-serif mt-4 w-40 rounded px-2 py-2 text-center' onClick={() => { logout() }}>
                            {t('nav7')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
