import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { modeAtom, langAtom, authAtom } from './core/config/atoms';

// import OneSignal from 'react-onesignal';

import PageAuth from './pages/PageAuth';
import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);
    const [authStatus] = useRecoilState(authAtom);
    const [lang] = useRecoilState(langAtom);

    const [initialized, setInitialized] = useState(false);

    const { t, i18n } = useTranslation();

    const loadWebPsuh = async () => {
        // await OneSignal.init({ appId: '2e472806-c0e6-4526-844e-8506850bb8b9' }).then(() => {
        //     setInitialized(true);
        //     OneSignal.showSlidedownPrompt().then(() => { });
        // });
    }

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
    }, [modeStatus]);

    useEffect(() => {
        if (!initialized) {
            loadWebPsuh();
        }
        i18n.changeLanguage(lang);
    }, []);

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageAuth />} />
                    {authStatus && <Route exact path="/home/:page/*" element={<PageHome />} />}
                    {authStatus && <Route exact path="/home/:page/:id" element={<PageHome />} />}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}