import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import Lottie from 'lottie-react';
import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { Menu, Transition } from '@headlessui/react';
import { useAlert } from 'react-alert';

import animPage from '../../assets/anim/anim-pageload.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceOrder from '../../services/serviceOrder';

export default function PanelReview(props) {

    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [reviews, setReviews] = useState([]);

    const [filter, setFilter] = useState({});

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isDate, setIsDate] = useState(false);

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const alert = useAlert();
    const orderService = new ServiceOrder();

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "positive":
                setFilter({ 'type': 'positive' });
                break;
            case "negative":
                setFilter({ 'type': 'negative' });
                break;
            default:
                break;
        }
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(reviews);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "reviews");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "reviews.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['username,banner,comment,rate,status,createdAt'];
        let usersCsv = reviews.reduce((rows, item) => {
            const { username, banner, comment, rate, status, createdAt } = item
            rows.push([username, banner, comment, rate, status, createdAt].join(','))
            return rows
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'reviews.csv',
            fileType: 'text/csv',
        });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getReview(body, token);
            if (res['status']) {
                setReviews(res.data.data);
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const pageChange = (pageValue) => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getReview(body, token);
            if (res['status']) {
                setTab('all');
                setReviews(res.data.data);
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> {t('pageA26')}
                                </h2>
                                <TopBar />
                            </div>
                            <div class="w-full mt-2">
                                <div className="p-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'positive' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('positive') }}>Positive</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'negative' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('negative') }}>Negative</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-4 px-2">
                            <div className="w-full shadow-md rounded-md border">
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr className=''>
                                            <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                                <div className='w-full grid grid-cols-12 gap-2'>
                                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                        <div className='col-span-4'>
                                                            <RangeDatePicker
                                                                startDate={sdate}
                                                                endDate={edate}
                                                                minDate={new Date(2022, 8, 1)}
                                                                maxDate={Moment().toDate()}
                                                                disabled={false}
                                                                onCloseCalendar={(e) => {
                                                                    loadData();
                                                                }}
                                                                onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                className=""
                                                                startWeekDay="monday"
                                                            />
                                                        </div>
                                                        <div className='w-full flex col-span-8 items-center'>
                                                            <div className='w-[80px]'>
                                                                <p>Search :</p>
                                                            </div>
                                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by username and comment." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        </div>
                                                    </form>
                                                    <div className='col-span-1 flex items-center'>
                                                        <Menu>
                                                            <Menu.Button>
                                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                            </Menu.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Menu.Items>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <Menu.Item>
                                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                        exportToExcel();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                        exportToCsv();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                User
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Review
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Rating
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            reviews.length !== 0 && reviews.map((review) => {
                                                return <tr className="hover:bg-gray-100">
                                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            <div className='flex items-center text-blue-600'>
                                                                <img src={review.banner.length === 0 ? 'https://via.placeholder.com/150' : review.banner} alt="User Image" className='w-10 h-10 rounded-full mr-2 border border-black' />
                                                                {review.username}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            {review.comment}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-yellow-200 text-yellow-600">
                                                            <p><i className="las la-star text-sm"></i> {review.rate.toFixed(2)}</p>
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-blue-600">
                                                            {Moment(review.createdAt).format('MMM DD, YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${review.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                            {review.status}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            reviews.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="5">
                                                    <div className="text-sm text-blue-600 text-center">
                                                        {t('misc5')}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div className='flex py-2 px-4 items-center bg-blue-50'>
                                    <p className='text-sm font-serif'>Show rows per page</p>
                                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    )

}