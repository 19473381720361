import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition } from '@headlessui/react';

import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import Lottie from 'lottie-react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import animPage from '../../assets/anim/anim-pageload.json';

import ServiceFeedback from '../../services/serviceFeedback';

export default function PanelFeedback(prpos) {

    const [token] = useRecoilState(tokenAtom);

    const [feeds, setFeeds] = useState([]);

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isDate, setIsDate] = useState(false);

    const { t } = useTranslation();
    const alert = useAlert();

    const feedbackService = new ServiceFeedback();

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(feeds);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "feedback");
        XLSX.writeFile(workbook, "feedback.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['name,subject,message,sent'];
        let usersCsv = feeds.reduce((rows, item) => {
            const { name, subject, message, sent } = item
            rows.push([name, subject, message, sent].join(','))
            return rows
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'feedback.csv',
            fileType: 'text/csv',
        });
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await feedbackService.getFeedback(body, token);
            if (res.status) {
                let fe = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    let doc = res.data.data[i];
                    fe.push({ name: doc.user.fname + " " + doc.user.lname, subject: doc.subject, message: doc.message, sent: doc.createdAt });
                }
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setFeeds(fe);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const pageChange = (pageValue) => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": {},
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await feedbackService.getFeedback(body, token);
            if (res.status) {
                let fe = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    let doc = res.data.data[i];
                    fe.push({ name: doc.user.fname + " " + doc.user.lname, subject: doc.subject, message: doc.message, sent: doc.createdAt });
                }
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setFeeds(fe);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div className="intro-y flex items-center justify-between h-10">
                            <h2 className="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { prpos.onToggle(); }}></motion.i> {t('pageA33')}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={4}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='col-span-4'>
                                                        <RangeDatePicker
                                                            startDate={sdate}
                                                            endDate={edate}
                                                            minDate={new Date(2022, 8, 1)}
                                                            maxDate={Moment().toDate()}
                                                            disabled={false}
                                                            onCloseCalendar={(e) => {
                                                                loadData();
                                                            }}
                                                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                            className=""
                                                            startWeekDay="monday"
                                                        />
                                                    </div>
                                                    <div className='w-full flex col-span-8 items-center'>
                                                        <div className='w-[80px]'>
                                                            <p>{t('misc4')} :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by subject." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                    </div>
                                                </form>
                                                <div className='col-span-1 flex items-center'>
                                                    <Menu>
                                                        <Menu.Button>
                                                            <div className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> {t('misc1')} </div>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToExcel();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">{t('misc2')}</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToCsv();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">{t('misc3')}</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Subject
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Message
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Sent At
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        feeds.length !== 0 && feeds.map((feed, index) => {
                                            return <tr className="hover:bg-gray-100" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap w-[80px]">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {feed.name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap w-[100px]">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {feed.subject}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-wrap w-[400px]">
                                                    <div className="text-sm text-gray-900">
                                                        <span style={{ wordWrap: 'break-word', maxWidth: '300px' }}>{feed.message.toString()}</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap w-[100px]">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {Moment(feed.sent).format('MMM, DD yyyy')}
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        feeds.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                <div className="text-sm text-blue-600 text-center">
                                                    {t('misc5')}
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className='flex py-2 px-4 items-center bg-blue-50'>
                                <p className='text-sm font-serif'>Show rows per page</p>
                                <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </select>
                                <div className='flex-grow'></div>
                                <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    )
}
