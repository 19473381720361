import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import { Menu, Transition } from '@headlessui/react';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';

import ServiceUser from '../../../services/serviceUser';

export default function ComponentReportVendor() {

    const [token] = useRecoilState(tokenAtom);

    const [users, setUsers] = useState([]);

    const [sdate, setSDate] = useState(Moment().add(-6, 'months').startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const alert = useAlert();
    const userService = new ServiceUser();

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        users.forEach((item) => {
            items.push({
                fname: item.fname,
                lname: item.lname,
                email: item.email,
                phone: item.phone,
                occupation: item.occupation,
                address: item.address[0].address,
                profileurl: item.profile[0].fileurl,
                isTech: item.isTech,
                type: item.type,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "customers");
        XLSX.writeFile(workbook, "customers.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['fname, lname, email, phone, occupation, address, profileurl, isTech, type, status, createdAt'];
        let usersCsv = getExportItems().reduce((acc, user) => {
            const { fname, lname, email, phone, occupation, address, profileurl, isTech, type, status, createdAt } = user
            acc.push([fname, lname, email, phone, occupation, address, profileurl, isTech, type, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'customers.csv',
            fileType: 'text/csv',
        });
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {
                    $or: [
                        { type: "technician" },
                        { type: "company" }
                    ]
                },
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await userService.getAllUsers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setUsers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": {
                    $or: [
                        { type: "technician" },
                        { type: "company" }
                    ]
                },
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await userService.getAllUsers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setUsers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate]);

    return (
        <Scrollbars>
            <div className="w-full shadow-md rounded-md border">
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr className=''>
                            <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                <div className='w-full grid grid-cols-12 gap-2'>
                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                        <div className='col-span-4'>
                                            <RangeDatePicker
                                                startDate={sdate}
                                                endDate={edate}
                                                minDate={new Date(2022, 8, 1)}
                                                maxDate={Moment().toDate()}
                                                disabled={false}
                                                onCloseCalendar={(e) => {
                                                    loadData();
                                                }}
                                                onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                className=""
                                                startWeekDay="monday"
                                            />
                                        </div>
                                        <div className='w-full flex col-span-8 items-center'>
                                            <div className='w-[80px]'>
                                                <p>Search :</p>
                                            </div>
                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email, and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                        </div>
                                    </form>
                                    <div className='col-span-1 flex items-center'>
                                        <Menu>
                                            <Menu.Button>
                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                            </Menu.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0">
                                                <Menu.Items>
                                                    <div
                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                <Menu.Item>
                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                        exportToExcel();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                        </div>
                                                                    </li>
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                        exportToCsv();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                        </div>
                                                                    </li>
                                                                </Menu.Item>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Email ID
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Contact Details
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                User Type
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                            <th scope="col" className="px-2 py-3"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            users.length !== 0 && users.map((user) => {
                                return <tr className="hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                            <div className='flex items-center text-blue-600'>
                                                <img src={user.profile.length === 0 ? 'https://via.placeholder.com/150' : user.profile[0].fileurl} alt="User Image" className='w-10 h-10 rounded-full mr-2 border border-black' />
                                                {user.fname} {user.lname}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            {user.email === "" ? "N/A" : user.email}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div>
                                            <div className="text-sm text-gray-500">
                                                Ph.: +243 {user.phone.substring(1)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-blue-600">
                                            {user.type}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${user.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                            {user.status.toUpperCase()}
                                        </span>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            users.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                    <div className="text-sm text-blue-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className='flex py-2 px-4 items-center bg-blue-50'>
                    <p className='text-sm font-serif'>Show rows per page</p>
                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                    <div className='flex-grow'></div>
                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                </div>
            </div>
        </Scrollbars>
    )
}
