import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServiceLearn from '../../../services/serviceLearn';

export default function ComponentCourseA({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let learnService = new ServiceLearn();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        disc: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        initialValues: {
            title: '',
            disc: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (props.isCourse) {
                let body = {
                    id: props.cid,
                    data: {
                        title: values.title,
                        disc: values.disc
                    }
                }
                learnService.updateCourse(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        alert.show("Course information updated.", { type: 'success' });
                        props.onStep(2, props.cid);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                    setSubmit(false);
                });
            } else {
                let body = {
                    title: values.title,
                    disc: values.disc,
                }
                learnService.setCourse(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        alert.show("Course information updated.", { type: 'success' });
                        props.onStep(2, res.data._id);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
        }
    });

    const loadData = () => {
        (async function () {
            if (props.isCourse) {
                populateData();
            }
        })();
    }

    const populateData = () => {
        let body = {
            "filter": { _id: props.cid },
            "page": 1,
            "size": 1,
            "searchQuery": "",
            "startDate": "",
            "endDate": ""
        };
        learnService.getCourse(body, token).then((res) => {
            if (res.status) {
                setValues({
                    title: res.data.data[0].title,
                    disc: res.data.data[0].description,
                });
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full bg-white shadow-lg rounded-md p-4">
            <div className='flex items-center justify-between'>
                <h3 className="text-xl font-semibold text-gray-900">
                    Course Basic Information &nbsp;
                </h3>
            </div>
            <hr className='mt-2' />
            <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Course Title<sup className="text-red-600">*</sup></label>
                    <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                    {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                </div>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Course Discription<sup className="text-red-600">*</sup></label>
                    <textarea type="text" id="disc" value={values.disc} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                    {(errors.disc && touched.disc) && <p className='text-xs text-red-400 mt-1'>{errors.disc}</p>}
                </div>
                <div className='flex items-end justify-end'>
                    <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                        {!submit && "NEXT"}
                    </button>
                </div>
            </form>
        </div>
    );
}
