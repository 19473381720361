import React, { useState } from 'react';

import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../core/config/atoms';

import ServiceOrder from '../services/serviceOrder';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

export default function DigPayment({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [perr, setPerr] = useState('');

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    const orderService = new ServiceOrder();

    const getTxns = (txn) => {
        let txns = [...props.order.txns];
        txns.push(txn);
        return txns;
    }

    const formVSchema = Yup.object().shape({
        mode: Yup.string().required('This information is required'),
        amount: Yup.string().required('This information is required'),
        ref: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'mode': '',
            'amount': '',
            'ref': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (parseFloat(values.amount) > parseFloat(props.order.invoices.pending)) {
                setPerr("Payment amount grater then pending amount.");
            } else {
                if (parseFloat(values.amount) < parseFloat(props.min)) {
                    setPerr("Payment amount less then minimum amount to be paid.");
                } else {
                    setSubmit(true);
                    let txn = `TXN${Math.round((new Date()).getTime() / 1000)}`;
                    let pending = parseFloat(props.order.invoices.pending) - parseFloat(values.amount);
                    var body = {
                        'id': props.order.oid,
                        'pmode': values.mode,
                        'pref': values.ref,
                        'uid': props.order.uid,
                        'oid': props.order._id,
                        'txn': txn,
                        'amount': values.amount,
                        'paid': parseFloat(props.order.invoices.paid) + parseFloat(values.amount),
                        'pending': pending,
                        'txns': getTxns(txn),
                        'pstatus': pending == 0 ? 'paid' : 'partial'
                    };
                    orderService.addPayment(body, token).then((d) => {
                        resetForm();
                        setPerr('');
                        props.onPayment();
                        setSubmit(false);
                        if (d.status) {
                            props.onOrder(d.data);
                            alert.show("Payment added to order.", { type: 'success' });
                        } else {
                            alert.show("Payment update error.", { type: 'error' });
                        }
                    });
                }
            }
        }
    });

    return (
        <Dialog open={props.status} onClose={() => {
            props.onPayment();
        }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Add Payment &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onPayment();
                        resetForm();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Payment Mode<sup className="text-red-600">*</sup></label>
                        <select type="text" name="mode" id="mode" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. CLEANERS" onChange={handleChange} value={values.mode}>
                            <option value="">-- Select Mode --</option>
                            <option value="bank" selected={"bank" == values.mode}>Bank Transfer</option>
                            <option value="online" selected={"online" == values.mode}>Online Transfer</option>
                            <option value="M2M" selected={"M2M" == values.mode}>Mobile number Transfer</option>
                            <option value="cheque" selected={"cheque" == values.mode}>Cheque Transfer</option>
                            <option value="cash" selected={"cash" == values.mode}>Cash Collection</option>
                        </select>
                        {(errors.mode && touched.mode) && <p className='text-xs text-red-400 mt-1'>{errors.mode}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Payment Amount <span className='text-xs text-gray-400'>(Tax inclusive)</span> <sup className="text-red-600">*</sup></label>
                        <input type="number" value={values.amount} name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. $200" onChange={handleChange} />
                        {(errors.amount && touched.amount) && <p className='text-xs text-red-400 mt-1'>{errors.amount}</p>}
                        {perr != "" && <p className='text-xs text-red-400 mt-1'>{perr}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Payment Reference<sup className="text-red-600">*</sup></label>
                        <input type="text" value={values.ref} name="ref" id="ref" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. XXXXXXX" onChange={handleChange} />
                        {(errors.ref && touched.ref) && <p className='text-xs text-red-400 mt-1'>{errors.ref}</p>}
                    </div>
                    <div className='flex items-end'>
                        <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "Add"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
