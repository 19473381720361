import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from '../panels/dev/PanelDev';

import PanelCustomer from '../panels/profiles/customers/PanelCustomer';
import PanelCustomerInfo from '../panels/profiles/customers/PanelCustomerInfo';

import PanelTechnician from '../panels/profiles/technicians/PanelTechnician';
import PanelTechnicianAdd from '../panels/profiles/technicians/PanelTechnicianAdd';
import PanelTechnicianInfo from '../panels/profiles/technicians/PanelTechnicianInfo';

import PanelCompany from '../panels/profiles/company/PanelCompany';
import PanelCompanyAdd from '../panels/profiles/company/PanelCompanyAdd';
import PanelCompanyInfo from '../panels/profiles/company/PanelCompanyInfo';

import PanelUsers from '../panels/profiles/users/PanelUsers';
import PanelUserAdd from '../panels/profiles/users/PanelUsersAdd';

export default function LayoutUser() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();
    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">{t('PanelStr2')}</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'customer' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('customer') }}>{t('PanelStr3')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'technician' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('technician') }}>{t('PanelStr4')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'compnay' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('compnay') }}>{t('PanelStr5')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'user' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('user') }}>{t('PanelStr6')}</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'customer':
                                return (<PanelCustomer onToggle={toggleMenu} />);
                            case 'customerInfo':
                                return (<PanelCustomerInfo onTap={closeMenu} />);
                            case 'technician':
                                return (<PanelTechnician onToggle={toggleMenu} />);
                            case 'technicianAdd':
                                return (<PanelTechnicianAdd onTap={closeMenu} />);
                            case 'technicianInfo':
                                return (<PanelTechnicianInfo onTap={closeMenu} />);
                            case 'compnay':
                                return (<PanelCompany onToggle={toggleMenu} />);
                            case 'companyAdd':
                                return (<PanelCompanyAdd onTap={closeMenu} />);
                            case 'companyInfo':
                                return (<PanelCompanyInfo onTap={closeMenu} />);
                            case 'user':
                                return (<PanelUsers onToggle={toggleMenu} />);
                            case 'userAdd':
                                return (<PanelUserAdd onTap={closeMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}