import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceUser {
    lookupService = new APIServiceLookUp();

    async loginUser(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/loginEmp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTokenById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/getTokenById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setEmp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/setEmp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEmail(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/updateEmail`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePhone(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/updatePhone`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateUsername(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/updateUsername`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePassword(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/updatePassword`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deactiveUser(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/deactiveUser`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllEmps(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/getAllEmps`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEmpById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}emp/updateUser`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllUsers(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}user/getAllUsers`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getUsersByIds(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}user/getUserByIds`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getUserInfoById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}user/getUserInfoById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getVendorInfoById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}user/getVendorInfoById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateUserById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}user/updateUserById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setUser(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setUserProfile`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async uploadUserFile(body, token) {
        var url = `${API_URL}file/uploadUserFile`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

}