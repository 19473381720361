import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';

import animPage from '../../assets/anim/anim-pageload.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ComponentSalesCard from './components/overview/ComponentSalesCard';
import ComponentOrderCard from './components/overview/ComponentOrderCard';
import ComponentDeuCard from './components/overview/ComponentDeuCard';
import ComponentCompanyCard from './components/overview/ComponentCompanyCard';
import ComponentTechnitionCard from './components/overview/ComponentTechnitionCard';
import ComponentServicesList from './components/overview/ComponentServicesList';
import ComponentTechList from './components/overview/ComponentTechList';
import ComponentCompList from './components/overview/ComponentCompList';
import ComponentDeuList from './components/overview/ComponentDeuList';
import ComponentUpcomingList from './components/overview/ComponentUpcomingList';

export default function PanelOverview(prpos) {
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="intro-y flex items-center justify-between h-10">
                <h2 className="text-2xl font-medium truncate ml-2">
                    <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { prpos.onToggle(); }}></motion.i> {t('pageA1')}
                </h2>
                <TopBar />
            </div>
            <hr className='mt-2' />
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4">
                        <ComponentSalesCard />
                        <ComponentOrderCard />
                        <ComponentDeuCard />
                        <ComponentCompanyCard />
                        <ComponentTechnitionCard />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                        <ComponentServicesList />
                        <ComponentTechList />
                        <ComponentCompList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                        <ComponentDeuList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                        <ComponentUpcomingList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                </Scrollbars>
            }
        </div>
    )

}