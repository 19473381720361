import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLearn {
    lookupService = new APIServiceLookUp();

    async getEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/getEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/setEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/delEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/getEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/setEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/updateEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/getCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/setCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/updateCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCourseSection(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/updateCourseSection`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setCourseC(body, token) {
        var url = `${API_URL}learn/setCourseC`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async setCourseD(body, token) {
        var url = `${API_URL}learn/setCourseD`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getCReviews(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}learn/getReview`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}