import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import Moment from 'moment';

import ServiceOverview from '../../../../services/serviceOverview';

export default function ComponentUpcomingList() {

    const [token] = useRecoilState(tokenAtom);

    const [orders, setOrders] = useState([]);

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const overviewService = new ServiceOverview();

    const getPBGChip = (status) => {
        switch (status) {
            case 'notpaid':
                return 'bg-red-100 text-red-800';
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'partial':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "quotation":
            case "sitevisit":
            case "booked":
                return "bg-blue-100 text-blue-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            default:
                return "bg-green-100 text-green-800";
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            const res = await overviewService.getUpcomingOrders({}, token);
            setOrders(res.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-12 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <h3 className='text-lg font-sans font-medium'>{t('pageA11')} | Top 10 Upcoming Orders</h3>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA15')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA16')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA17')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA18')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA19')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                {t('pageA20')}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            orders.length !== 0 && orders.map((order, index) => {
                                return <tr className="cursor-pointer hover:bg-gray-100" key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                            Bookind Date: {Moment(order.bookingDate).format('MMM DD, YYYY')}<br />
                                            Orders ID: {order.oid}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {order.categorie.title}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {order.sub.title}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div>
                                            <div className="text-sm font-bold text-gray-900">
                                                {order.user.fname} {order.user.lname}
                                            </div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {order.user.email}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                Ph.: +243 {order.user.phone}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {order.address.title}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {order.address.address}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getPBGChip(order.pstatus)}`}>
                                            {order.pstatus}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                            {order.status}
                                        </span>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                    <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                        <p className='text-center'>{t('pageA22')}</p>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
