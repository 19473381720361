import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from '../panels/dev/PanelDev';
import PanelOverview from '../panels/home/PanelOverview';
import PanelNotification from '../panels/home/PanelNotification';
import PanelReview from '../panels/home/PanelReview';
import PanelAssets from '../panels/home/PanelAssets';
import PanelTestimonial from '../panels/home/PanelTestimonial';
import PanelServices from '../panels/home/PanelServices';
import PanelFeedback from '../panels/home/PanelFeedback';
import PanelContact from '../panels/home/PanelContact';

export default function FragmentHome() {

    const [isOpen, setIsOpen] = useState(false);

    const { page } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const itemVariants = {
        closed: {
            opacity: 0
        },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">{t('pageA24')}</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'overview' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('overview') }}>{t('pageA1')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'notification' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('notification') }}>{t('pageA25')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'reviews' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('reviews') }}>{t('pageA26')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'assets' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('assets') }}>{t('pageA27')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'testimonial' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('testimonial') }}>{t('pageA28')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'services' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('services') }}>{t('pageA29')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'feedback' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('feedback') }}>{t('pageA30')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'contact' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('contact') }}>{t('pageA31')}</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {(() => {
                    switch (page) {
                        case 'overview':
                            return (<PanelOverview onToggle={toggleMenu} />);
                        case 'notification':
                            return (<PanelNotification onToggle={toggleMenu} />);
                        case 'reviews':
                            return (<PanelReview onToggle={toggleMenu} />);
                        case 'assets':
                            return (<PanelAssets onToggle={toggleMenu} />);
                        case 'testimonial':
                            return (<PanelTestimonial onToggle={toggleMenu} />);
                        case 'services':
                            return (<PanelServices onToggle={toggleMenu} />);
                        case 'feedback':
                            return (<PanelFeedback onToggle={toggleMenu} />);
                        case 'contact':
                            return (<PanelContact onToggle={toggleMenu} />);
                        default:
                            return (<PanelDev />);
                    }
                })()}
            </div>
        </>
    )
}
