import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { useAlert } from 'react-alert';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import ServiceUser from '../../../services/serviceUser';

import TopBar from '../../../core/widgets/ui/TopBar';

export default function PanelUsers(props) {

    const [token] = useRecoilState(tokenAtom);

    const [users, setUsers] = useState([]);

    const [filter, setFilter] = useState({ 'type': 'customer' });

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();
    const userService = new ServiceUser();

    const addVendor = () => {
        navigate(`/home/userAdd`);
    }

    const onClickUser = (id) => {
        navigate(`/home/userInfo/${id}`);
    }

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            case "deleted":
                setFilter({ 'status': 'deleted' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(users);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "users");
        XLSX.writeFile(workbook, "users.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['fname,lname,email,phone,occupation,address'];
        let usersCsv = users.reduce((acc, user) => {
            const { fname, lname, email, phone, occupation, address } = user
            acc.push([fname, lname, email, phone, occupation, address].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'users.csv',
            fileType: 'text/csv',
        });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const updateStatus = (id, status) => {
        let body = {
            uid: id,
            data: {
                status: status
            }
        }
        userService.updateEmpById(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await userService.getAllEmps(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setUsers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await userService.getAllEmps(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setUsers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full py-3 px-3">
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Users Master
                            </h2>
                            <div className='flex'>
                                <div>
                                    <button className="py-2 px-3 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => { addVendor() }}><i class="las la-plus mr-2"></i> Add User</button>
                                </div>
                                <TopBar />
                            </div>
                        </div>
                        <div class="w-full mt-2">
                            <div className="p-2 mt-2">
                                <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>Active</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'banned' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('banned') }}>Banned</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'deleted' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('deleted') }}>Deleted</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='col-span-4'>
                                                        <RangeDatePicker
                                                            startDate={sdate}
                                                            endDate={edate}
                                                            minDate={new Date(2022, 8, 1)}
                                                            maxDate={Moment().toDate()}
                                                            disabled={false}
                                                            onCloseCalendar={(e) => {
                                                                loadData();
                                                            }}
                                                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                            className=""
                                                            startWeekDay="monday"
                                                        />
                                                    </div>
                                                    <div className='w-full flex col-span-8 items-center'>
                                                        <div className='w-[80px]'>
                                                            <p>Search :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email, and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                    </div>
                                                </form>
                                                <div className='col-span-1 flex items-center'>
                                                    <Menu>
                                                        <Menu.Button>
                                                            <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToExcel();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToCsv();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            USER ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Email ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Contact Details
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            User Type
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Status
                                        </th>
                                        <th scope="col" className="px-2 py-3"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        users.length !== 0 && users.map((user) => {
                                            return <tr className="hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => { onClickUser(user._id) }}>
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div className='flex items-center text-blue-600'>
                                                            {user.uid}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900 uppercase">
                                                        {user.fname} {user.lname}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {user.email}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div>
                                                        <div className="text-sm text-gray-500">
                                                            Ph.: +243 {user.phone.substring(1)}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-blue-600">
                                                        {user.role}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${user.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                        {user.status.toUpperCase()}
                                                    </span>
                                                </td>
                                                <td>
                                                    {
                                                        user.role != "SADMIN" && <Menu>
                                                            <Menu.Button>
                                                                <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                            </Menu.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Menu.Items>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                {
                                                                                    user.status == 'active' && <Menu.Item>
                                                                                        <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            updateStatus(user._id, 'banned');
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-ban text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Block</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                                {
                                                                                    user.status == 'banned' && <Menu.Item>
                                                                                        <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            updateStatus(user._id, 'active');
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-chevron-circle-up text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Active</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                                {
                                                                                    user.status == 'banned' && <Menu.Item>
                                                                                        <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            updateStatus(user._id, 'deleted');
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-trash text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                                {
                                                                                    user.status == 'deleted' && <Menu.Item>
                                                                                        <li className='w-32 cursor-not-allowed hover:bg-red-100 px-2 py-1 rounded-md'>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-trash text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>NONE</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        users.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                                <div className="text-sm text-blue-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className='flex py-2 px-4 items-center bg-blue-50'>
                                <p className='text-sm font-serif'>Show rows per page</p>
                                <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </select>
                                <div className='flex-grow'></div>
                                <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div >
    )

}