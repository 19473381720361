import React from 'react';

import PanelReports from "../panels/reports/PanelReports";

export default function LayoutReport() {
    return (
        <>
            <div className='h-screen w-full'>
                <PanelReports />
            </div>
        </>
    )
}
