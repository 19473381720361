import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import animEmpty from '../../assets/anim/anim-empty.json';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAPI from '../../services/serviceService';

export default function PanelServices(prpos) {

    const [token] = useRecoilState(tokenAtom);

    const [catIcon, setCatIcon] = useState(null);

    const [category, setCategory] = useState([]);
    const [subcategory, setSubCategory] = useState([]);

    const [formData, setFormData] = useState({});

    const [lang, setLang] = useState('En');

    const [isOpenMain, setIsOpenMain] = useState(false);
    const [isOpenSub, setIsOpenSub] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const { t } = useTranslation();

    const alert = useAlert();

    let apiService = new ServiceAPI();

    const handleOnChangeCategory = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        if (isOpenMain && !isUpdate) {
            setSubmit(true);
            var formDataC = new FormData();

            formDataC.append("title", formData.title);
            formDataC.append("lang", lang);
            formDataC.append("icon", catIcon);

            apiService.addCategory(formDataC, token).then((d) => {
                document.getElementById("category-form").reset();
                if (d.status) {
                    setSubmit(false);
                    setIsOpenMain(false);
                    setCatIcon(null);
                    setFormData({});
                    loadData(lang);
                    alert.show("New category created.", { type: 'success' });
                } else {
                    alert.show("Category creation error.", { type: 'error' });
                }
            });
        }

        if (isOpenMain && isUpdate) {
            setSubmit(true);
            var formDataC = new FormData();

            formDataC.append("id", formData.id);
            formDataC.append("title", formData.title);
            formDataC.append("lang", lang);
            formDataC.append("icon", catIcon);

            apiService.editCategory(formDataC, token).then((d) => {
                document.getElementById("category-form").reset();
                if (d.status) {
                    setSubmit(false);
                    setIsOpenMain(false);
                    setCatIcon(null);
                    setFormData({});
                    loadData(lang);
                    alert.show("category updated.", { type: 'success' });
                } else {
                    alert.show("Category updation error.", { type: 'error' });
                }
            });
        }

        if (isOpenSub && !isUpdate) {
            setSubmit(true);
            let body = {
                "cid": formData.cat,
                "title": formData.title,
                "disc": formData.disc,
                "fxrate": formData.fxrate,
                "strate": formData.strate,
                "lang": lang,
            }
            apiService.addSubCategory(body, token).then((d) => {
                document.getElementById("service-form").reset();
                if (d.status) {
                    setSubmit(false);
                    setIsOpenSub(false);
                    setFormData({});
                    loadData(lang);
                    alert.show("New sub category created.", { type: 'success' });
                } else {
                    alert.show("Sub category creation error.", { type: 'error' });
                }
            });
        }

        if (isOpenSub && isUpdate) {
            setSubmit(true);
            let body = {
                "id": formData.id,
                "update": {
                    "cid": formData.cat,
                    "title": formData.title,
                    "disc": formData.disc,
                    "fxrate": formData.fxrate,
                    "strate": formData.strate,
                    "lang": lang
                }
            }
            apiService.editSubCategory(body, token).then((d) => {
                document.getElementById("service-form").reset();
                if (d.status) {
                    setSubmit(false);
                    setIsOpenSub(false);
                    setIsUpdate(false);
                    setFormData({});
                    loadData(lang);
                    alert.show("Sub category updated.", { type: 'success' });
                } else {
                    alert.show("Sub category updation error.", { type: 'error' });
                }
            });
        }
    }

    const getSubCats = (id) => {
        let data = [...subcategory];
        return data.filter((c) => c.catid == id);
    }

    const updateStatus = (id, status) => {
        let body = {
            "id": id,
            "update": {
                "status": status
            }
        }
        apiService.editSubCategory(body, token).then((d) => {
            if (d.status) {
                loadData(lang);
                alert.show("Sub category updated.", { type: 'success' });
            } else {
                alert.show("Category updation error.", { type: 'error' });
            }
        });
    }

    const updateCatStatus = (id, status) => {
        let body = {
            "id": id,
            "update": {
                "status": status
            }
        }
        apiService.updateCategory(body, token).then((d) => {
            if (d.status) {
                loadData(lang);
                alert.show("category updated.", { type: 'success' });
            } else {
                alert.show("Category updation error.", { type: 'error' });
            }
        });
    }

    const editSubCat = (s) => {
        let form = {
            id: s._id,
            cat: s.catid,
            disc: s.disc,
            fxrate: s.fxrate,
            strate: s.strate,
            title: s.title
        }
        setIsUpdate(true);
        setFormData(form);
        setIsOpenSub(true);
    }

    const editCat = (c) => {
        let form = {
            id: c._id,
            title: c.title,
        }
        setIsUpdate(true);
        setFormData(form);
        setIsOpenMain(true);
    }

    const loadData = (lan) => {
        (async function () {
            var resA = await apiService.getAllCategory({ 'lang': lan }, token);
            setCategory(resA.data);

            var resB = await apiService.getSubCategory({ 'lang': lan }, token);
            setSubCategory(resB.data);
        })();
    }

    useEffect(() => {
        loadData('En');
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <Dialog open={isOpenMain} onClose={() => setIsOpenMain(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            {t('pageA36')} &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenMain(false);
                            setCatIcon(null);
                            document.getElementById("category-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handeleSubmit} id="category-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA42')}<sup className="text-red-600">*</sup></label>
                            <input type="text" value={formData.title} name="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Carpentry" required onChange={handleOnChangeCategory} />
                        </div>
                        <div className="mb-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    {t('pageA43')}<sup className="text-red-600">*</sup>
                                </label>
                            </div>
                            {
                                catIcon === null &&
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setCatIcon(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG up to 512kb
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                catIcon !== null &&
                                <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={URL.createObjectURL(catIcon)} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {catIcon.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setCatIcon(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && t('pageA44')}
                                {!submit && isUpdate && t('setng13')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog open={isOpenSub} onClose={() => {
                setIsOpenSub(false);
                document.getElementById("service-form").reset();
                if (isUpdate) {
                    setIsUpdate(false);
                    setFormData({});
                }
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            {t('pageA37')} &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenSub(false);
                            document.getElementById("service-form").reset();
                            if (isUpdate) {
                                setIsUpdate(false);
                                setFormData({});
                            }
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handeleSubmit} id="service-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA45')}<sup className="text-red-600">*</sup></label>
                            <select type="text" name="cat" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. CLEANERS" required onChange={handleOnChangeCategory}>
                                <option value="">-- Select Category --</option>
                                {
                                    category.length != 0 && category.map((cat) => {
                                        return <option value={cat._id} selected={cat._id == formData.cat}>{cat.title}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA46')}<sup className="text-red-600">*</sup></label>
                            <input type="text" name="title" value={formData.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. CLEANERS" required onChange={handleOnChangeCategory} />
                        </div>
                        <div className='grid grid-cols-3 gap-2 mb-6'>
                            <div className="col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA47')}<sup className="text-red-600">*</sup></label>
                                <input type="number" name="strate" value={formData.strate} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. 5$" required onChange={handleOnChangeCategory} />
                            </div>
                            <div className="col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA48')}<sup className="text-red-600">*</sup></label>
                                <input type="number" name="fxrate" value={formData.fxrate} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. 5$" required onChange={handleOnChangeCategory} />
                            </div>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('pageA55')}<sup className="text-red-600">*</sup></label>
                            <textarea type="text" name="disc" value={formData.disc} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required onChange={handleOnChangeCategory} />
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && t('pageA44')}
                                {!submit && isUpdate && t('setng13')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2 px-4">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { prpos.onToggle(); }}></motion.i> {t('pageA34')}
                            </h2>
                            <div className='flex'>
                                <Menu>
                                    <Menu.Button>
                                        <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-language mr-2"></i> Language (<span className='text-xs'>{lang}</span>)</button>
                                    </Menu.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0">
                                        <Menu.Items>
                                            <div
                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                                <div>
                                                    <ul className='flex flex-col items-start p-2'>
                                                        <Menu.Item>
                                                            <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'En' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                setLang('En');
                                                                loadData('En');
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className={`text-sm font-serif ml-2 ${lang === 'En' ? 'font-medium text-green-600' : ''}`}>English</p>
                                                                </div>
                                                                {lang === 'En' && <i className={`las la-check-circle ${lang === 'En' ? 'font-medium text-green-600' : ''}`}></i>}
                                                            </li>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'Fr' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                setLang('Fr');
                                                                loadData('Fr');
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className={`text-sm font-serif ml-2 ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}>French</p>
                                                                </div>
                                                                {lang === 'Fr' && <i className={`las la-check-circle ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}></i>}
                                                            </li>
                                                        </Menu.Item>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu>
                                    <Menu.Button>
                                        <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-plus mr-2"></i> {t('pageA35')}</button>
                                    </Menu.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0">
                                        <Menu.Items>
                                            <div
                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                                <div>
                                                    <ul className='flex flex-col items-start p-2'>
                                                        <Menu.Item>
                                                            <li className='w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md' onClick={() => {
                                                                setIsOpenMain(true);
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className='text-sm font-serif ml-2'>{t('pageA36')}</p>
                                                                </div>
                                                            </li>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <li className='w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md' onClick={() => {
                                                                setIsOpenSub(true);
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className='text-sm font-serif ml-2'>{t('pageA37')}</p>
                                                                </div>
                                                            </li>
                                                        </Menu.Item>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                    <hr className='mt-2 col-span-12' />
                    {
                        category.length !== 0 && category.map((cat) => {
                            return <>
                                <div className="intro-y mt-6 col-span-12">
                                    <div className='flex justify-between items-center'>
                                        <div className='flex items-center'>
                                            <img src={cat.iconURL} alt='category icon' className='w-8 h-8 bg-white rounded shadow mr-2' />
                                            <h1 className='text-lg font-medium text-black'>{cat.title}</h1> &nbsp; (<span className={`font-bold text-sm ${cat.status === 'inactive' ? 'text-red-600' : 'text-green-600'}`}>{cat.status.toUpperCase()}</span>)
                                        </div>
                                        <div className='flex'>
                                            <div className={`w-20 cursor-pointer px-2 py-1 rounded-md bg-blue-100 hover:bg-blue-200`} onClick={() => {
                                                editCat(cat)
                                            }}>
                                                <div className='flex items-center text-base'>
                                                    <i className="las la-pen text-lg"></i>
                                                    <p className='text-sm font-serif ml-2'>{t('pageA38')}</p>
                                                </div>
                                            </div>
                                            <div className={`w-32 cursor-pointer px-2 py-1 ml-4 rounded-md ${cat.status === 'inactive' ? 'bg-green-100 hover:bg-green-200' : 'bg-red-100 hover:bg-red-200'}`} onClick={() => {
                                                updateCatStatus(cat._id, cat.status === 'inactive' ? 'active' : 'inactive');
                                            }}>
                                                <div className='flex items-center text-base'>
                                                    {cat.status !== 'inactive' && <i className="las la-ban text-lg"></i>}
                                                    {cat.status === 'inactive' && <i className="las la-chevron-circle-up text-lg"></i>}
                                                    <p className='text-sm font-serif ml-2'>{cat.status === 'inactive' ? t('pageA40') : t('pageA39')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                                        <ul className='w-full'>
                                            {
                                                getSubCats(cat._id).length !== 0 && getSubCats(cat._id).map((s) => {
                                                    return <>
                                                        <li className='px-2 py-1'>
                                                            <div className='grid grid-cols-12 gap-2'>
                                                                <div className='col-span-2 flex'>
                                                                    <div className='h-full w-1 bg-green-600 mr-2 rounded-tl-md rounded-bl-md'></div>
                                                                    <h3 className="text-lg font-medium text-gray-900">{s.title}</h3>
                                                                </div>
                                                                <div className='col-span-2'>
                                                                    <p className='text-gray-800 font-medium text-sm'>Fixed Rate Charges : <span className='text-green-800 font-medium'>{s.fxrate}$</span></p>
                                                                </div>
                                                                <div className='col-span-2'>
                                                                    <p className='text-gray-800 font-medium text-sm'>Site Visit Charges : <span className='text-green-800 font-medium'>{s.strate}$</span></p>
                                                                </div>
                                                                <p className='text-gray-800 text-sm col-span-1'>Orders: <span className='text-black font-medium'>{s.count}</span></p>
                                                                <div className='col-span-2'>
                                                                    <span className={`text-xs text-white rounded-full px-4 py-1 ${s.status === 'inactive' ? 'bg-red-600' : 'bg-green-600'}`}>
                                                                        {s.status.toUpperCase()}
                                                                    </span>
                                                                </div>
                                                                <p className="text-sm font-medium text-green-600 col-span-1">Total earnnings ${s.ernnings}</p>
                                                                <div className='col-span-2 flex justify-end'>
                                                                    <Popover className="relative">
                                                                        <Popover.Button>
                                                                            <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                                        </Popover.Button>
                                                                        <Transition
                                                                            enter="transition duration-100 ease-out"
                                                                            enterFrom="transform scale-95 opacity-0"
                                                                            enterTo="transform scale-100 opacity-100"
                                                                            leave="transition duration-75 ease-out"
                                                                            leaveFrom="transform scale-100 opacity-100"
                                                                            leaveTo="transform scale-95 opacity-0">
                                                                            <Popover.Panel>
                                                                                <div
                                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-136px' }}>
                                                                                    <div>
                                                                                        <ul className='flex flex-col items-start p-2'>
                                                                                            <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                                editSubCat(s);
                                                                                            }}>
                                                                                                <div className='flex items-center text-base'>
                                                                                                    <i className="las la-pen text-lg"></i>
                                                                                                    <p className='text-sm font-serif ml-2'>Edit</p>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className={`w-32 cursor-pointer px-2 py-1 rounded-md ${s.status === 'inactive' ? 'hover:bg-green-100' : 'hover:bg-red-100'}`} onClick={() => {
                                                                                                updateStatus(s._id, s.status === 'inactive' ? 'active' : 'inactive');
                                                                                            }}>
                                                                                                <div className='flex items-center text-base'>
                                                                                                    {s.status !== 'inactive' && <i className="las la-ban text-lg"></i>}
                                                                                                    {s.status === 'inactive' && <i className="las la-chevron-circle-up text-lg"></i>}
                                                                                                    <p className='text-sm font-serif ml-2'>{s.status === 'inactive' ? 'Active' : 'Inactive'}</p>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </Popover.Panel>
                                                                        </Transition>
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                            <hr className='opacity-20 my-2' />
                                                        </li>
                                                    </>
                                                })
                                            }
                                            {
                                                getSubCats(cat._id).length === 0 && <>
                                                    <h1 className='text-sm font-serif text-blue-600 text-center'>{t('pageA41')}</h1>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </>
                        })
                    }
                    {
                        category.length === 0 && <div className="intro-y mt-6 w-full h-full col-span-12 flex flex-col items-center justify-center">
                            <Lottie animationData={animEmpty} className="w-[280px] h-[280px]" loop={true} />
                            <h1 className='text-lg font-serif font-medium text-gray-900 text-center'>{t('pageA54')}</h1>
                        </div>
                    }
                </div>
            </Scrollbars>
        </div >
    )

}