import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { Menu, Transition } from '@headlessui/react';
import { motion } from "framer-motion";

import ServiceOverview from '../../../../services/serviceOverview';
import ServiceAPI from '../../../../services/serviceService';

export default function ComponentServicesList() {
    const [token] = useRecoilState(tokenAtom);

    const [services, setServices] = useState([]);
    const [servic, setServic] = useState([]);
    const [selection, setSelection] = useState([]);

    const [loading, setLoading] = useState(true);
    const [isAll, setIsAll] = useState(true);

    const { t } = useTranslation();

    const overviewService = new ServiceOverview();
    let apiService = new ServiceAPI();

    const getIsSelected = (title) => {
        if (isAll) {
            return true;
        } else {
            return selection.includes(title);
        }
    }

    const toggleAll = () => {
        if (!isAll) {
            let ser = [];
            servic.map((servi) => {
                ser.push(servi._id);
            });
            setSelection(ser);
        }
        setIsAll(!isAll);
    }

    const togleTag = (tag) => {
        if (!isAll) {
            let tags = [...selection];
            if (tags.includes(tag)) {
                tags.splice(tags.indexOf(tag), 1);
            } else {
                tags.push(tag);
            }
            setSelection(tags);
        }
    }

    const populate = () => {
        setLoading(true);
        (async function () {
            let body = {
                'type': isAll ? 'all' : 'filter',
                'filter': selection,
            }
            const res = await overviewService.getTopService(body, token);
            setServices(res.data);
            setLoading(false);
        })();
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            var resA = await apiService.getAllCategory({}, token);
            let ser = []
            resA.data.map((servi) => {
                ser.push(servi._id);
            });
            setSelection(ser);
            setServic(resA.data);

            let body = {
                'type': 'all',
                'filter': [],
            }
            const res = await overviewService.getTopService(body, token);
            setServices(res.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        populate();
    }, [isAll, selection]);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>{t('pageA7')}</h3>
                    <div className='relative'>
                        <Menu>
                            <Menu.Button>
                                <motion.div whileTap={{ scale: 0.90 }}>
                                    <div className='w-8 h-8 rounded-full hover:bg-gray-100 cursor-pointer mr-4'>
                                        <i className="las la-ellipsis-v text-lg cursor-pointer"></i>
                                    </div>
                                </motion.div>
                            </Menu.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0">
                                <Menu.Items>
                                    <div className='bg-white shadow-md rounded-md absolute max-h-[200px] overflow-y-scroll' style={{ top: '6px', left: '-140px' }}>
                                        <div className='flex flex-col items-start p-2'>
                                            <div className='w-40 px-2 text-sm cursor-pointer flex items-center'>
                                                <input type="checkbox" checked={isAll} onChange={() => toggleAll()} id="all" />
                                                <label className='ml-2 cursor-pointer' for="all">Select All</label>
                                            </div>
                                            <div className='w-40 h-[1px] bg-gray-400 my-2'></div>
                                            <ul>
                                                {
                                                    servic.map((cat, index) => {
                                                        return <li className='w-40 px-2 text-xs my-1 cursor-pointer flex items-center' key={index}>
                                                            <input type="checkbox" className='bg-white rounded' checked={getIsSelected(cat._id)} onChange={() => togleTag(cat._id)} id={cat._id} />
                                                            <label className='ml-2 cursor-pointer' for={cat._id}>{cat.title}</label>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (services.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>{t('pageA12')}</p>
                    </div>
                }
                {
                    services.map((service) => {
                        return <div className='w-full flex my-2'>
                            <img src={service.category.iconURL} alt='Banner image' className='rounded w-[40px] h-[40px]' />
                            <div className='flex flex-col items-start justify-start ml-2'>
                                <h1 className='text-sm font-medium text-black'>{service.title}</h1>
                                <p className='text-xs font-medium text-gray-600'>{service.category.title}</p>
                            </div>
                            <div className='flex-grow'></div>
                            <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                <p><i className="las la-star text-sm"></i> {service.rateing.toFixed(2)}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
