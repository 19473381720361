import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import { motion } from "framer-motion";
import { Transition, Menu } from '@headlessui/react';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import Moment from 'moment';
import { useAlert } from 'react-alert';

import animPage from '../../assets/anim/anim-pageload.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceLearn from '../../services/serviceLearn';

export default function PanelCourses(props) {

    const [token] = useRecoilState(tokenAtom);

    const [courses, setCourses] = useState([]);

    const [filter, setFilter] = useState({});

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isDate, setIsDate] = useState(false);

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const alert = useAlert();
    const navigate = useNavigate();

    let learnService = new ServiceLearn();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "draft":
                setFilter({ 'status': 'draft' });
                break;
            case "published":
                setFilter({ 'status': 'published' });
                break;
            default:
                break;
        }
    }

    const addCourse = () => {
        props.onTap();
        navigate(`/home/course/add`);
    }

    const gotoCourse = (id, status) => {
        props.onTap();
        if (status === "draft") {
            navigate(`/home/course/${id}`);
        } else {
            navigate(`/home/courseInfo/${id}`);
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const updateStatus = (id, status) => {
        let body = {
            id: id,
            data: {
                status: status
            }
        }
        learnService.updateCourse(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await learnService.getCourse(body, token);
            if (res.status) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setCourses(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const pageChange = (pageValue) => {
        setLoading(true);
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await learnService.getCourse(body, token);
            if (res.status) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setCourses(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {!loading &&
                <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i>  Courses
                                </h2>
                                <div className='flex items-center'>
                                    <div className='flex items-center'>
                                        <button className="py-1 px-3 text-sm rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => { addCourse() }}><i class="las la-plus mr-1"></i> Add Course</button>
                                    </div>
                                    <TopBar />
                                </div>
                            </div>
                            <hr className='mt-2' />
                            <div class="w-full mt-2">
                                <div className="p-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'draft' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('draft') }}>Draft</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'published' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('published') }}>Published</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y col-span-12 grid grid-cols-12 gap-2">
                            <div className="mt-2 col-span-12 shadow-md rounded-md">
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr className=''>
                                            <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                                <div className='w-full grid grid-cols-12 gap-2'>
                                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                        <div className='col-span-4'>
                                                            <RangeDatePicker
                                                                startDate={sdate}
                                                                endDate={edate}
                                                                minDate={new Date(2022, 8, 1)}
                                                                disabled={false}
                                                                onCloseCalendar={(e) => {
                                                                    loadData();
                                                                }}
                                                                onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                className=""
                                                                startWeekDay="monday"
                                                            />
                                                        </div>
                                                        <div className='w-full flex col-span-8 items-center'>
                                                            <div className='w-[80px]'>
                                                                <p>Search :</p>
                                                            </div>
                                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by title." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                COURSE
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                PRICE
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                ENROLLMENT
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                REVIEW
                                            </th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                -
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            courses.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="5">
                                                    <div className="text-sm text-blue-600 text-center">
                                                        No data found.
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            courses.length !== 0 && courses.map((course) => {
                                                return <tr className="hover:bg-gray-100">
                                                    <td className="cursor-pointer px-6 py-4 whitespace-nowrap" onClick={() => { gotoCourse(course._id, course.status) }}>
                                                        <div className='flex'>
                                                            <div>
                                                                <img src={course.banneurl === "" ? 'https://via.placeholder.com/260x160' : course.banneurl} alt='Banner image' className='rounded w-[260px] h-[160px]' />
                                                            </div>
                                                            <div className='ml-6 flex flex-col'>
                                                                <div className="text-2xl font-medium text-gray-900 uppercase">
                                                                    {course.title.toUpperCase()}
                                                                </div>
                                                                <div className="text-sm font-serif text-gray-400 uppercase">
                                                                    Created: {Moment(course.createdAt).format('MMMM DD, YYYY - hh:mm a')}
                                                                </div>
                                                                <div className='flex-grow'></div>
                                                                {
                                                                    course.status === "published" && <div className="uppercase mb-2 flex items-center">
                                                                        <div className='mr-2 w-5 h-5 bg-green-600 text-white flex items-center justify-center p-1 rounded-full'><i className="las la-check text-xs"></i></div>
                                                                        <p className='text-sm text-green-800 font-bold font-serif'>Published</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    course.status === "draft" && <div className="uppercase mb-2 flex items-center">
                                                                        <div className='mr-2 w-5 h-5 bg-red-600 text-white flex items-center justify-center p-1 rounded-full'>
                                                                            <i className="las la-inbox text-xs"></i>
                                                                        </div>
                                                                        <p className='text-sm text-red-600 font-bold font-serif'>Draft</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-4 whitespace-nowrap">
                                                        {
                                                            course.rate !== 0 && <span className="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase">
                                                                ${course.rate}
                                                            </span>
                                                        }
                                                        {
                                                            course.rate === 0 && <span className="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase">
                                                                FREE
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <div className="text-base text-gray-500">
                                                            Enrollment: <span className='text-black font-bold'>({course.nop})</span>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-4 whitespace-nowrap">
                                                        <span className="px-4 py-1 inline-flex leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-600 uppercase">
                                                            <div className='flex items-center justify-center'>
                                                                <span className='mr-2 text-sm'>{course.review}</span>
                                                                <i className="las la-star text-sm"></i>
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Menu>
                                                            <Menu.Button>
                                                                <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                            </Menu.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Menu.Items>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                {
                                                                                    course.status == 'draft' && <Menu.Item>
                                                                                        <li className='w-36 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            updateStatus(course._id, 'published');
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-certificate text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Published</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                                {
                                                                                    course.status == 'published' && <Menu.Item>
                                                                                        <li className='w-36 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            updateStatus(course._id, 'draft');
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-certificate text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Un-Published</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                                {
                                                                                    course.status == 'published' && <Menu.Item>
                                                                                        <li className='w-36 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                            gotoCourse(course._id, course.status);
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <i className="las la-list-alt text-lg"></i>
                                                                                                <p className='text-sm font-serif ml-2'>Enrollment</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className='flex py-2 px-4 items-center bg-blue-50'>
                                    <p className='text-sm font-serif'>Show rows per page</p>
                                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    )

}