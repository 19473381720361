import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOverview {
    lookupService = new APIServiceLookUp();

    async getSalesCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getSalesCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getOrderCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDeuCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getDeuCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCompanyCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getCompanyCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTechnicationCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getTechnicationCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopService(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getTopService`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopTech(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getTopTech`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopComp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getTopComp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDeuPayments(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getDeuPayments`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getUpcomingOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getUpcomingOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}