import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import { motion } from "framer-motion";
import { Dialog, Popover, Transition } from '@headlessui/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from 'react-alert';
import { Document, Page, pdfjs } from 'react-pdf';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ServiceUser from '../../../services/serviceUser';
import ServiceAPI from '../../../services/serviceService';

import animPage from '../../../assets/anim/anim-pageload.json';

export default function PanelCompanyInfo(props) {

    const [token] = useRecoilState(tokenAtom);

    const { id } = useParams();

    const [orders, setOrders] = useState([]);
    const [tags, setTags] = useState([]);
    const [services, setServices] = useState([]);

    const [user, setUser] = useState({});

    const [tab, setTab] = useState('a');
    const [url, setURL] = useState('');

    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isTag, setIsTag] = useState(false);
    const [isPic, setIsPic] = useState(false);
    const [isLocation, setIsLocation] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();
    const userService = new ServiceUser();
    const apiService = new ServiceAPI();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const formVSchema = Yup.object().shape({
        location: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues, handleReset, resetForm } = useFormik({
        initialValues: {
            'location': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            let body = {
                id: id,
                data: {
                    location: values.location,
                }
            }
            userService.updateUserById(body, token).then((res) => {
                if (res['status']) {
                    resetForm();
                    setIsLocation(false);
                    loadData();
                } else {
                    alert.show('Server error please try again', { type: 'error' });
                }
            });
        }
    });

    const goBack = () => {
        navigate(`/home/compnay`);
    }

    const viewDocument = (type) => {
        let doc = {};
        let ext = "";
        switch (type) {
            case "Identity document":
                doc = user.docs.filter((elem) => elem.title === "Identity document")[0];
                ext = doc.filename.split('.')[1];
                setURL(doc.fileurl);
                if (ext == 'pdf') {
                    setIsPic(false);
                } else {
                    setIsPic(true);
                }
                break;
            case "Company RCCM":
                doc = user.docs.filter((elem) => elem.title === "Company RCCM")[0];
                ext = doc.filename.split('.')[1];
                setURL(doc.fileurl);
                if (ext == 'pdf') {
                    setIsPic(false);
                } else {
                    setIsPic(true);
                }
                break;
            case "Portfolio":
                doc = user.docs.filter((elem) => elem.title === "Portfolio")[0];
                ext = doc.filename.split('.')[1];
                setURL(doc.fileurl);
                if (ext == 'pdf') {
                    setIsPic(false);
                } else {
                    setIsPic(true);
                }
                break;
        }
        setIsOpen(true);
    }

    const gotoLink = (url) => {
        window.open(url, "_blank");
    }

    const isAva = (type) => {
        let doc = user.docs.filter((elem) => elem.title === type);
        return doc.length != 0;
    }

    function onDocumentLoadSuccess() {
        //Nothing to do.
    }

    const updateTag = (title) => {
        let ts = [...tags];
        if (ts.includes(title)) {
            ts.splice(ts.indexOf(title), 1);
        } else {
            ts.push(title);
        }
        setTags(ts);
    }

    const applyTag = () => {
        if (tags.length === 0) {
            alert.show('Atleast one tag is mandetory', { type: 'error' });
        } else {
            let body = {
                id: id,
                data: {
                    tags: tags
                }
            }
            userService.updateUserById(body, token).then((res) => {
                if (res['status']) {
                    loadData();
                    setIsTag(false);
                } else {
                    alert.show('Server error please try again', { type: 'error' });
                }
            });
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "quotation":
            case "sitevisit":
            case "booked":
                return "bg-blue-100 text-blue-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            default:
                return "bg-green-100 text-green-800";
        }
    }

    const getPBGChip = (status) => {
        switch (status) {
            case 'notpaid':
                return 'bg-red-100 text-red-800';
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'partial':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = { 'id': id, };
            var res = await userService.getVendorInfoById(body, token);
            if (res['status']) {
                setTab('a');
                setUser(res.data.user);
                setOrders(res.data.orders);
                setTags(res.data.user.tags);
                setValues({ 'location': res.data.user.location });
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            var resB = await apiService.getSubCategory({}, token);
            setServices(resB.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        props.onTap();
        loadData();
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Document viewer &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    {
                        isPic && <div>
                            <img src={url} alt='Document' className='w-full rounded border-2' />
                        </div>
                    }
                    {
                        !isPic &&
                        <div className="w-[860px] h-[700px] overflow-scroll">
                            <Document
                                file={url}
                                onLoadSuccess={onDocumentLoadSuccess} className="w-full h-960">
                                <Page pageNumber={1} />
                            </Document>
                        </div>
                    }
                    <hr className='my-2' />
                    <button type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center">
                        Print <i className="las la-print text-base"></i>
                    </button>
                </div>
            </Dialog>
            <Dialog open={isTag} onClose={() => setIsTag(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Tag Editor &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsTag(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <div className='w-[520px]'>
                        <Scrollbars>
                            {
                                services.map((item) => {
                                    return <div className={`mt-1 p-2 rounded cursor-pointer flex justify-between ${tags.includes(item.title) ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-black'}`} onClick={() => { updateTag(item.title) }}>
                                        <h2 className='text-sm font-medium'>{item.title}</h2>
                                        {tags.includes(item.title) && <i className='las la-check-circle font-medium text-green-600'></i>}
                                    </div>
                                })
                            }
                        </Scrollbars>
                    </div>
                    <hr className='my-2' />
                    <button type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center" onClick={() => applyTag()}>
                        APPLY
                    </button>
                </div>
            </Dialog>
            <Dialog open={isLocation} onClose={() => setIsLocation(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Location Editor &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsLocation(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Location<sup className="text-red-600">*</sup></label>
                            <input type="text" value={values.location} id="location" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. location" />
                            {(errors.location && touched.location) && <p className='text-xs text-red-400 mt-1'>{errors.location}</p>}
                        </div>
                        <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center">
                            APPLY
                        </button>
                    </form>
                </div>
            </Dialog>
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2 p-3">
                    {
                        !loading && <>
                            <div className='col-span-12'>
                                <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                    <i className="las la-long-arrow-alt-left text-xl"></i>
                                    <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all companies</h4>
                                </div>
                                <div className="intro-y mt-4">
                                    <h3 className="text-2xl font-sans font-bold text-gray-800">company Report</h3>
                                    <p className="mt-1 text-sm text-gray-600 mb-2">
                                        All information related to company.
                                    </p>
                                    <hr />
                                </div>
                            </div>
                            <div className='col-span-12'>

                            </div>
                            <div className='col-span-12'>
                                <div class="w-full mt-2">
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'a' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('a') }}>Admin information</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'b' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('b') }}>Company</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'c' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('c') }}>Report</button></li>
                                        </ul>
                                    </div>
                                    {
                                        tab === 'a' && <div className='w-full'>
                                            <div className='grid grid-cols-4 gap-2'>
                                                <div className='col-span-1 bg-white rounded-md p-4'>
                                                    <div className='flex flex-col'>
                                                        <img src={user.profile.length == 0 ? "https://via.placeholder.com/150?text=Profile" : user.profile[0].fileurl} alt='Company profile' className='w-full h-96 rounded border-2' />
                                                    </div>
                                                </div>
                                                <div className='col-span-3 bg-white rounded-md p-4'>
                                                    <div className='flex justify-between items-center'>
                                                        <h3 className='text-xl font-sans font-medium'>Company admin</h3>
                                                    </div>
                                                    <hr className='my-4' />
                                                    <div className='grid grid-cols-12 gap-2'>
                                                        <div className='col-span-2'>
                                                            <span className='text-sm font-normal text-gray-600'>First name</span>
                                                            <p className='text-base font-medium'>{user.fname}</p>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <span className='text-sm font-normal text-gray-600'>Last name</span>
                                                            <p className='text-base font-medium'>{user.lname}</p>
                                                        </div>
                                                        <div className='col-span-3'>
                                                            <span className='text-sm font-normal text-gray-600'>Email id</span>
                                                            <p className='text-base font-medium'>{user.email === "" ? "N/A" : user.email}</p>
                                                        </div>
                                                        <div className='col-span-3'>
                                                            <span className='text-sm font-normal text-gray-600'>Phone number</span>
                                                            <p className='text-base font-medium'>+243 {user.phone}</p>
                                                        </div>
                                                        <div className='col-span-2'></div>
                                                        <div className='col-span-2 mt-4'>
                                                            <span className='text-sm font-normal text-gray-600'>Occupation</span>
                                                            <p className='text-base font-medium'>{user.occupation}</p>
                                                        </div>
                                                        <div className='col-span-2 mt-4'>
                                                            <span className='text-sm font-normal text-gray-600'>Tax ID Number</span>
                                                            <p className='text-base font-medium'>{user.tax ? user.tax : '-'}</p>
                                                        </div>
                                                        <div className='col-span-12 mt-4'>
                                                            <span className='text-sm font-normal text-gray-600'>Address</span>
                                                            <p className='text-base font-medium'>{user.address[0].address}</p>
                                                        </div>
                                                    </div>
                                                    <hr className='my-2' />
                                                    <h3 className='text-lg font-sans font-medium text-green-600'>Documents</h3>
                                                    <hr className='my-2' />
                                                    <div className='grid grid-cols-12 gap-2'>
                                                        {
                                                            isAva('Identity document') && <div className='col-span-4 flex justify-between items-center px-4 border h-12 rounded-md'>
                                                                <p className='text-sm font-sans font-medium'>National Identity</p>
                                                                <span className='bg-green-600 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer' onClick={() => {
                                                                    viewDocument('Identity document');
                                                                }}>
                                                                    <i className="las la-file-alt text-white"></i>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        tab === 'b' && <div className='w-full'>
                                            <div className='grid grid-cols-4 gap-2'>
                                                <div className='col-span-1 bg-white rounded-md p-4'>
                                                    <div className='flex flex-col'>
                                                        <img src={user.profile[0].fileurl} alt='Technician profile' className='w-full h-96 rounded border-2' />
                                                        <div>
                                                            <div className='mt-2 flex justify-between'>
                                                                <span className='text-sm font-normal'>Tags:</span>
                                                                <span className='text-sm cursor-pointer font-medium text-blue-600' onClick={() => {
                                                                    setIsTag(true)
                                                                }}><i className="las la-tags"></i>&nbsp;EDIT</span>
                                                            </div>
                                                            {
                                                                <ul className='flex flex-wrap py-1'>
                                                                    {
                                                                        user.tags.map((tag) => {
                                                                            return <li className='bg-green-200 text-green-800 text-xs mx-1 my-1 font-medium flex items-center justify-center rounded-full px-3 py-1'>{tag}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                        <hr />
                                                        <div className='mt-2 flex'>
                                                            <h1 className='text-sm'>Location: &nbsp;</h1>
                                                            <h1 className='text-sm text-blue-600 font-medium'>{user.location ? user.location : "-"}</h1>
                                                            <div className='flex-grow'></div>
                                                            <span className='text-sm cursor-pointer font-medium text-blue-600' onClick={() => { setIsLocation(true) }}><i className="las la-pen"></i>&nbsp;EDIT</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-span-3 bg-white rounded-md p-4'>
                                                    <div className='flex justify-between items-center'>
                                                        <h3 className='text-xl font-sans font-medium'>Company information</h3>
                                                    </div>
                                                    <hr className='my-4' />
                                                    <div className='grid grid-cols-12 gap-2'>
                                                        <div className='col-span-2'>
                                                            <span className='text-sm font-normal text-gray-600'>Company name</span>
                                                            <p className='text-base font-medium'>{user.companyInfo.name}</p>
                                                        </div>
                                                        <div className='col-span-12 mt-4'>
                                                            <span className='text-sm font-normal text-gray-600'>Company Address</span>
                                                            <p className='text-base font-medium'>{user.companyInfo.address != "" ? user.companyInfo.address : "NA"}</p>
                                                        </div>
                                                        <div className='col-span-12 mt-4'>
                                                            <span className='text-sm font-normal text-gray-600'>About Company</span>
                                                            <p className='text-base font-medium'>{user.disc != "" ? user.disc : "NA"}</p>
                                                        </div>
                                                    </div>
                                                    <hr className='my-2' />
                                                    <h3 className='text-lg font-sans font-medium text-green-600'>Documents</h3>
                                                    <hr className='my-2' />
                                                    <div className='grid grid-cols-12 gap-2'>
                                                        {
                                                            isAva('Company RCCM') && <div className='col-span-4 flex justify-between items-center px-4 border h-12 rounded-md'>
                                                                <p className='text-sm font-sans font-medium'>Company RCCM</p>
                                                                <span className='bg-green-600 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer' onClick={() => {
                                                                    viewDocument('Company RCCM');
                                                                }}>
                                                                    <i className="las la-file-alt text-white"></i>
                                                                </span>
                                                            </div>
                                                        }
                                                        {
                                                            isAva('Portfolio') && <div className='col-span-4 flex justify-between items-center px-4 border h-12 rounded-md'>
                                                                <p className='text-sm font-sans font-medium'>Portfolio File</p>
                                                                <span className='bg-green-600 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer' onClick={() => {
                                                                    viewDocument('Portfolio');
                                                                }}>
                                                                    <i className="las la-file-alt text-white"></i>
                                                                </span>
                                                            </div>
                                                        }
                                                        {
                                                            user.link != "" && <div className='col-span-4 flex justify-between items-center px-4 border h-12 rounded-md'>
                                                                <p className='text-sm font-sans font-medium'>Website Link</p>
                                                                <span className='bg-green-600 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer' onClick={() => {
                                                                    gotoLink(user.link);
                                                                }}>
                                                                    <i className="las la-link text-white"></i>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        tab === 'c' && <div className='w-full bg-white rounded-md p-4'>
                                            <h3 className='text-lg font-sans font-medium'>Orders</h3>
                                            <hr className='my-4' />
                                            <table className="min-w-full divide-y divide-gray-200 px-4 mt-2">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            Order ID
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            Service Details
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            User Details
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            Location Details
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            Payment Details
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                            Order Status
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {
                                                        orders.length !== 0 && orders.map((order, index) => {
                                                            return <tr className="cursor-pointer hover:bg-gray-100" key={index}>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm font-bold text-gray-900 uppercase">
                                                                        Orders ID: {order.oid}
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {order.categorie.title}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            {order.sub.title}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    <div>
                                                                        <div className="text-sm font-bold text-gray-900">
                                                                            {order.user.fname} {order.user.lname}
                                                                        </div>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {order.user.email}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            Ph.: +243 {order.user.phone}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {order.address.title}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            {order.address.address}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getPBGChip(order.pstatus)}`}>
                                                                        {order.pstatus}
                                                                    </span>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                                                        {order.status}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                    {
                                                        orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                                <div className="text-sm text-blue-600 text-center">
                                                                    No order found.
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Scrollbars>
        </div>
    )
}
