import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServiceLearn from '../../../services/serviceLearn';

const allowedExtensions = ["png", "jpeg"];

export default function ComponentCourseC({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [file, setFile] = useState(null);
    const [ofile, setOFile] = useState({});

    const [isOfile, setIsOFile] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let learnService = new ServiceLearn();

    const formVSchema = Yup.object().shape({
        auther: Yup.string().required('This information is required'),
        rate: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        initialValues: {
            auther: '',
            rate: 0,
            outcome: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (file != null || isOfile) {
                setSubmit(true);
                if (isOfile) {
                    var formData = new FormData();

                    formData.append("id", props.cid);
                    formData.append("auther", values.auther);
                    formData.append("rate", values.rate);
                    formData.append("outcome", values.outcome);

                    learnService.setCourseD(formData, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            alert.show("Course information updated.", { type: 'success' });
                            props.onStep(4, '');
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                        setSubmit(false);
                    });
                } else {
                    var formData = new FormData();

                    formData.append("id", props.cid);
                    formData.append("auther", values.auther);
                    formData.append("rate", values.rate);
                    formData.append("outcome", values.outcome);
                    formData.append("doc", file);

                    learnService.setCourseC(formData, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            alert.show("Course information updated.", { type: 'success' });
                            props.onStep(4, '');
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                        setSubmit(false);
                    });
                }
            } else {
                setSubmit(false);
                alert.show("Please select all the mandatory document!", { type: 'error' });
            }
        }
    })

    const loadData = () => {
        (async function () {
            if (props.isCourse) {
                populateData();
            }
        })();
    }

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setIsOFile(false);
        setFile(inputFile);
    };

    const populateData = () => {
        let body = {
            "filter": { _id: props.cid },
            "page": 1,
            "size": 1,
            "searchQuery": "",
            "startDate": "",
            "endDate": ""
        };
        learnService.getCourse(body, token).then((res) => {
            if (res.status) {
                if (res.data.data[0].auther !== "") {
                    setIsOFile(true);
                    setOFile({ name: res.data.data[0].bannername, url: res.data.data[0].banneurl });
                    setValues({
                        auther: res.data.data[0].auther,
                        rate: res.data.data[0].rate,
                        outcome: res.data.data[0].outcome
                    });
                }
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full bg-white shadow-lg rounded-md p-4">
            <div className='flex items-center justify-between'>
                <h3 className="text-xl font-semibold text-gray-900">
                    Course Basic Information &nbsp;
                </h3>
            </div>
            <hr className='mt-2' />
            <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Course Author<sup className="text-red-600">*</sup></label>
                    <input type="text" id="auther" value={values.auther} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                    {(errors.auther && touched.auther) && <p className='text-xs text-red-400 mt-1'>{errors.auther}</p>}
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Course Price<sup className="text-red-600">*</sup></label>
                    <input type="number" id="rate" value={values.rate} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                    {(errors.rate && touched.rate) && <p className='text-xs text-red-400 mt-1'>{errors.rate}</p>}
                </div>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Course Completion Message</label>
                    <textarea type="text" id="outcome" value={values.outcome} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                    {(errors.outcome && touched.outcome) && <p className='text-xs text-red-400 mt-1'>{errors.outcome}</p>}
                </div>
                <div className="mb-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Course Banner<sup className="text-red-600">*</sup>
                        </label>
                    </div>
                    {
                        (file === null && !isOfile) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                        <span>Upload a file</span>
                                        <input type="file" className="sr-only" required onChange={handleFileChange} />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                    PNG, JPG up to 512kb
                                </p>
                            </div>
                        </div>
                    }
                    {
                        (file === null && isOfile) && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                            <div className="w-0 flex-1 flex items-center">
                                <img src={ofile.url} alt="Icon Template" className='w-10 h-10' />
                                <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                    {ofile.name}&nbsp;
                                    <i className="las la-link text-gray-400 text-lg"></i>
                                </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                    <span>Replace</span>
                                    <input type="file" className="sr-only" onChange={handleFileChange} />
                                </label>
                            </div>
                        </div>
                    }
                    {
                        file !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                            <div className="w-0 flex-1 flex items-center">
                                <img src={URL.createObjectURL(file)} alt="Icon Template" className='w-10 h-10' />
                                <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                    {file.name}&nbsp;
                                    <i className="las la-link text-gray-400 text-lg"></i>
                                </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                    <span>Replace</span>
                                    <input type="file" className="sr-only" onChange={handleFileChange} />
                                </label>
                            </div>
                        </div>
                    }
                </div>
                <div className='flex items-end justify-end'>
                    <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                        {!submit && "SUBMIT"}
                    </button>
                </div>
            </form>
        </div>
    );
}
