import React from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutOrder from '../layouts/LayoutOrder';
import LayoutUser from '../layouts/LayoutUser';
import LayoutSetting from '../layouts/LayoutSetting';
import LayoutCourses from '../layouts/LayoutCourses';
import LayoutReport from '../layouts/LayoutReport';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {
                (() => {
                    switch (page) {
                        case 'overview':
                        case 'notification':
                        case 'reviews':
                        case 'assets':
                        case 'testimonial':
                        case 'services':
                        case 'feedback':
                        case 'contact':
                            return (<LayoutHome />);
                        case 'order':
                            return (<LayoutOrder />);
                        case 'customer':
                        case 'customerInfo':
                        case 'technician':
                        case 'technicianAdd':
                        case 'technicianInfo':
                        case 'compnay':
                        case 'companyAdd':
                        case 'companyInfo':
                        case 'user':
                        case 'userAdd':
                            return (<LayoutUser />);
                        case 'event':
                        case 'course':
                        case 'courseInfo':
                            return (<LayoutCourses />);
                        case 'report':
                            return (<LayoutReport />);
                        case 'setting':
                            return (<LayoutSetting />);
                        default:
                            return (
                                <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                    Coming Soon
                                </div>
                            );
                    }
                })()
            }
        </div>
    )
}